import { useEffect } from "react";
import { AuthProvider } from "@hooks/useAuthStore";
import { UserProvider } from "@hooks/useUserStore";
import { LiveUserProvider } from "./hooks/useLiveUserStore";
import { SidebarProvider } from "./contexts/SidebarContext";
import CookieBanner from "@components/CookieBanner";
import { initGA } from "@utils/analytics";
import AppRoutes from "./AppRoutes";

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your measurement ID
    initGA("G-MQF7N3R9");
  }, []);

  return (
    <AuthProvider>
      <UserProvider>
        <LiveUserProvider>
          <SidebarProvider>
            <AppRoutes />
            <CookieBanner />
          </SidebarProvider>
        </LiveUserProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
