import { API_BASE_URL, API_ROUTE_VODS_MANAGE } from "@constants";

/**
 * Request a signed URL from the backend for direct upload to Google Cloud Storage
 * @param filename - The name of the file to upload
 * @param contentType - The content type of the file
 * @param metadata - Metadata about the file (title, summary, sport, sportEventId)
 * @param token - The authentication token
 * @returns The signed URL for direct upload and the vodId
 */
export async function getSignedUrl(
  filename: string, 
  contentType: string, 
  metadata: {
    title: string;
    summary?: string;
    sport: string;
    sportEventId?: string;
    thumbnail?: string;
  },
  token: string
): Promise<{ signedUrl: string; vodId: string }> {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        filename,
        contentType,
        title: metadata.title,
        summary: metadata.summary,
        sport: metadata.sport,
        sportEventId: metadata.sportEventId
      })
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Failed to get signed URL');
    }
    
    return {
      signedUrl: data.data.signedUrl,
      vodId: data.data.vodId
    };
  } catch (error) {
    console.error('Error getting signed URL:', error);
    throw error;
  }
}

/**
 * Upload a file directly to Google Cloud Storage using a signed URL with fetch API
 * @param signedUrl - The signed URL for upload
 * @param file - The file to upload
 * @param onProgress - Optional callback for tracking upload progress
 * @returns A promise that resolves when the upload is complete
 */
export async function uploadToGCS(
  signedUrl: string, 
  file: File, 
  onProgress?: (progress: number) => void
): Promise<boolean> {
  if (onProgress) {
    // If progress tracking is needed, use a more compatible approach
    return uploadWithProgress(signedUrl, file, onProgress);
  } else {
    // Simple upload without progress tracking
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Origin': window.location.origin
      },
      body: file
    });
    
    if (!response.ok) {
      throw new Error('Failed to upload file to GCS');
    }
    
    return true;
  }
}

/**
 * Helper function to upload with progress tracking using fetch
 * This implementation uses a more compatible approach with most browsers
 */
async function uploadWithProgress(
  signedUrl: string,
  file: File,
  onProgress: (progress: number) => void
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // Check if browser supports the more modern approach
    if (typeof file.stream === 'function' && typeof ReadableStream !== 'undefined') {
      // Use the modern streaming approach
      uploadWithReadableStream(signedUrl, file, onProgress)
        .then(resolve)
        .catch(reject);
    } else {
      // Fallback to a more compatible approach using chunks
      uploadWithChunks(signedUrl, file, onProgress)
        .then(resolve)
        .catch(reject);
    }
  });
}

/**
 * Modern approach using ReadableStream
 */
async function uploadWithReadableStream(
  signedUrl: string,
  file: File,
  onProgress: (progress: number) => void
): Promise<boolean> {
  try {
    const contentLength = file.size;
    let loadedBytes = 0;
    
    const fileStream = new ReadableStream({
      start(controller) {
        const reader = file.stream().getReader();
        
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            
            loadedBytes += value.byteLength;
            const percentComplete = Math.round((loadedBytes / contentLength) * 100);
            onProgress(percentComplete);
            
            controller.enqueue(value);
            push();
          }).catch(error => {
            controller.error(error);
          });
        }
        
        push();
      }
    });
    
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Content-Length': contentLength.toString(),
        'Origin': window.location.origin
      },
      body: fileStream,
      duplex: 'half'
    } as RequestInit);
    
    if (!response.ok) {
      throw new Error('Failed to upload file to GCS');
    }
    
    return true;
  } catch (error) {
    console.error('Error in uploadWithReadableStream:', error);
    throw error;
  }
}

/**
 * Fallback approach for browsers that don't support ReadableStream
 * This uses a simple fetch without progress tracking
 */
async function uploadWithChunks(
  signedUrl: string,
  file: File,
  onProgress: (progress: number) => void
): Promise<boolean> {
  try {
    // Since we can't track progress with fetch directly in older browsers,
    // we'll simulate progress updates
    // const totalSize = file.size;
    const updateInterval = setInterval(() => {
      // Simulate progress from 0 to 90% during the upload
      // The last 10% will be set when the upload completes
      const simulatedProgress = Math.min(90, Math.round(Math.random() * 90));
      onProgress(simulatedProgress);
    }, 500);
    
    // Perform the actual upload
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Origin': window.location.origin
      },
      body: file
    });
    
    // Clear the interval and set progress to 100%
    clearInterval(updateInterval);
    onProgress(100);
    
    if (!response.ok) {
      throw new Error('Failed to upload file to GCS');
    }
    
    return true;
  } catch (error) {
    console.error('Error in uploadWithChunks:', error);
    throw error;
  }
}

/**
 * Finalize the upload by notifying the backend
 * @param filename - The name of the uploaded file
 * @param vodId - The ID of the VOD returned from the signed URL request
 * @param token - The authentication token
 * @returns The response from the backend
 */
export async function finalizeUpload(
  filename: string, 
  vodId: string,
  token: string
): Promise<any> {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/finalize-upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        filename,
        vodId
      })
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Failed to finalize upload');
    }
    
    return data;
  } catch (error) {
    console.error('Error finalizing upload:', error);
    throw error;
  }
}

/**
 * Request a signed URL from the backend for direct thumbnail upload to Google Cloud Storage
 * @param filename - The name of the thumbnail file to upload
 * @param contentType - The content type of the file (should be image/png)
 * @param vodId - The ID of the VOD to associate the thumbnail with
 * @param token - The authentication token
 * @returns The signed URL for direct upload
 */
export async function getThumbnailSignedUrl(
  filename: string, 
  contentType: string, 
  vodId: string,
  token: string
): Promise<{ signedUrl: string }> {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/thumbnail/signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        filename,
        contentType,
        vodId
      })
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Failed to get thumbnail signed URL');
    }
    
    return {
      signedUrl: data.data.signedUrl
    };
  } catch (error) {
    console.error('Error getting thumbnail signed URL:', error);
    throw error;
  }
}

/**
 * Finalize the thumbnail upload by notifying the backend
 * @param filename - The name of the uploaded thumbnail file
 * @param vodId - The ID of the VOD to associate the thumbnail with
 * @param token - The authentication token
 * @returns The response from the backend
 */
export async function finalizeThumbnailUpload(
  filename: string, 
  vodId: string,
  token: string
): Promise<any> {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/thumbnail/finalize-upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        filename,
        vodId
      })
    });
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Failed to finalize thumbnail upload');
    }
    
    return data;
  } catch (error) {
    console.error('Error finalizing thumbnail upload:', error);
    throw error;
  }
} 