export default function Landing2() {
  return (
    <section className="w-full flex flex-col items-center justify-center bg-[#141414] text-center px-4 py-12 md:px-8 md:py-24">
      <h2 className="text-4xl md:text-5xl font-anton text-secondary mb-4 md:mb-6 italic">
        QUÉ OFRECEMOS
      </h2>
      <p className="text-base md:text-lg max-w-2xl text-white px-2 font-bold">
        Nos comprometemos a brindarte la mejor experiencia de visionado.
      </p>
      <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4 md:mt-8 w-full max-w-4xl">
        <div className="relative h-[40vh] md:h-[60vh] md:flex-1">
          <div className="w-full h-full">
            <img
              src="/img/landing/SEGUNDA PANTALLA 1.jpg"
              alt="Vista cenital de un campo de fútbol"
              className="w-full h-full object-cover shadow-lg"
            />
            <p className="absolute top-[66%] mx-4 md:mx-4 left-0 right-0 font-medium text-white font-anton py-2 italic text-xl md:text-2xl uppercase">
              EL DEPORTE BASE DE LA REGIÓN DE MURCIA
            </p>
          </div>
        </div>
        <div className="sm:block relative h-[40vh] md:h-[60vh] md:flex-1">
          <div className="w-full h-full">
            <img
              src="/img/landing/SEGUNDA PANTALLA 2.jpg"
              alt="Móvil grabando un partido de fútbol"
              className="w-full h-full object-cover shadow-lg"
            />
            <p className="absolute top-[66%] mx-4 left-0 right-0 font-medium text-white font-anton py-2 italic text-2xl uppercase">
              EN DIRECTO Y BAJO DEMANDA
            </p>
          </div>
        </div>
        <div className="relative h-[40vh] md:h-[60vh] md:flex-1 overflow-hidden">
          <div className="w-full h-full">
            <img
              src="/img/landing/banner_land_2.png"
              alt="Persona accediendo a la app en un portátil"
              className="w-full h-full object-cover md:object-[center_75%] md:object-bottom"
            />
            <p className="absolute top-[66%] mx-4 md:mx-4 left-0 right-0 font-medium text-white font-anton py-2 italic text-xl md:text-2xl uppercase">
              GRATIS PARA CLIENTES DE LIBERA
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
