import { API_BASE_URL } from "@constants";
import { ILiveStream } from "@hooks/useLiveStream";
import { IVod } from "@hooks/useVod";
import SpinnerLoader from "../SpinnerLoader";

interface CatalogSectionProps {
  title: string;
  color: string;
  textColor: string;
  defaultImageSrc: string;
  noResultsImageSrc: string;
  matches: (IVod | ILiveStream)[];
  maxMatches?: number;
  seeAll?: boolean;
  seeAllLink?: string;
  isLoading?: boolean;
}

interface VodCardProps {
  vod: IVod | ILiveStream;
  defaultImageSrc: string;
  bgColorClass: string;
  textColorClass: string;
  borderColorClass: string;
}

const LiveStreamCard: React.FC<VodCardProps> = ({
  vod,
  defaultImageSrc,
  bgColorClass,
  textColorClass,
  borderColorClass,
}) => {
  const liveStream = vod as ILiveStream;
  const titleParts = liveStream.title?.split(" VS ") || [liveStream.title];
  const thumbnail = liveStream.thumbnail
    ? API_BASE_URL + liveStream.thumbnail
    : defaultImageSrc;

  return (
    <a href={`live/${liveStream.slug}`} className="block">
      <div className="relative aspect-[4/3] overflow-hidden group transition-all duration-300">
        <div
          className={`absolute inset-0 ${bgColorClass} bg-blend-multiply group-hover:bg-blend-overlay transition-all duration-300`}
          style={{
            backgroundImage: `url(${thumbnail || defaultImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
        <div
          className={`absolute inset-0 ${bgColorClass} bg-blend-soft-light group-hover:bg-blend-overlay transition-all duration-300`}
          style={{
            backgroundImage: `url(${thumbnail || defaultImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
        <div
          className={`absolute inset-0 bg-black bg-opacity-20 group-hover:bg-opacity-10 flex flex-col items-center justify-center p-4 transition-all duration-300 ${textColorClass}`}
        >
          {liveStream.isLive && (
            <div className="absolute top-2 left-2 flex items-center">
              <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse mr-2" />
              <span className="text-xs font-bold">DIRECTO</span>
            </div>
          )}
          <p className="text-center font-extrabold text-lg leading-[0.9] mb-1">
            {titleParts[0]}
          </p>
          {titleParts.length > 1 && (
            <>
              <p className="text-center font-bold text-xl leading-[0.9] my-1">
                VS
              </p>
              <p className="text-center font-extrabold text-lg leading-[0.9] mt-1">
                {titleParts[1]}
              </p>
            </>
          )}
          <span
            className={`mt-4 px-4 py-1 border-2 ${borderColorClass} text-sm rounded-md transition-all duration-300 font-bold`}
          >
            Ver
          </span>
        </div>
      </div>
    </a>
  );
};

const VodCard: React.FC<VodCardProps> = ({
  vod,
  defaultImageSrc,
  bgColorClass,
  textColorClass,
  borderColorClass,
}) => {
  const vodContent = vod as IVod;
  const titleParts = vodContent.title?.split(" VS ") || [vodContent.title];
  const thumbnail = vodContent.thumbnail
    ? API_BASE_URL + vodContent.thumbnail
    : defaultImageSrc;

  return (
    <a href={`vods/${vodContent.id}`} className="block">
      <div className="relative aspect-[4/3] overflow-hidden group transition-all duration-300">
        <div
          className={`absolute inset-0 ${bgColorClass} bg-blend-multiply group-hover:bg-blend-overlay transition-all duration-300`}
          style={{
            backgroundImage: `url(${thumbnail || defaultImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
        <div
          className={`absolute inset-0 ${bgColorClass} bg-blend-soft-light group-hover:bg-blend-overlay transition-all duration-300`}
          style={{
            backgroundImage: `url(${thumbnail || defaultImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
        <div
          className={`absolute inset-0 bg-black bg-opacity-20 group-hover:bg-opacity-10 flex flex-col items-center justify-center p-4 transition-all duration-300 ${textColorClass}`}
        >
          <p className="text-center font-extrabold text-lg leading-[0.9] mb-1">
            {titleParts[0]}
          </p>
          {titleParts.length > 1 && (
            <>
              <p className="text-center font-bold text-xl leading-[0.9] my-1">
                VS
              </p>
              <p className="text-center font-extrabold text-lg leading-[0.9] mt-1">
                {titleParts[1]}
              </p>
            </>
          )}
          <span
            className={`mt-4 px-4 py-1 border-2 ${borderColorClass} text-sm rounded-md transition-all duration-300 font-bold`}
          >
            Ver
          </span>
        </div>
      </div>
    </a>
  );
};

function isLiveStream(item: IVod | ILiveStream): item is ILiveStream {
  return (item as ILiveStream).isLive !== undefined;
}

const CatalogSection: React.FC<CatalogSectionProps> = ({
  title,
  color,
  textColor,
  defaultImageSrc,
  noResultsImageSrc,
  matches,
  maxMatches = 0,
  seeAll,
  seeAllLink,
  isLoading = false,
}) => {
  // Derive color classes
  const bgColorClass = `bg-${color}`;
  const titleColorClass = `text-${color}`;
  const textColorClass = `text-${textColor}`;
  const borderColorClass = `border-${textColor}`;

  // Calculate how many matches to show and whether to show "Ver todo"
  const showSeeAll =
    (seeAll && seeAllLink != null) ||
    (maxMatches != null && matches.length > maxMatches && seeAllLink != null);
  const matchesToShow = showSeeAll ? matches.slice(0, maxMatches - 1) : matches;

  return (
    <div className="pt-14 mb-16 bg-black">
      {/* Title with lines */}
      <div className="flex items-center justify-center w-full mb-8">
        <div className={`flex-grow h-px ${bgColorClass}`}></div>
        <h2 className={`px-4 text-3xl italic font-anton ${titleColorClass}`}>
          {title}
        </h2>
        <div className={`flex-grow h-px ${bgColorClass}`}></div>
      </div>

      {/* Grid of matches */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 px-4 md:px-8">
        {isLoading ? (
          <div className="col-span-full flex justify-center">
            <SpinnerLoader />
          </div>
        ) : matches.length === 0 && !showSeeAll ? (
          <div className="col-span-full flex flex-col items-center justify-center py-8">
            <img
              src={noResultsImageSrc}
              alt="No hay resultados"
              className="max-w-xs w-full mb-4"
            />
            <p className={`text-lg ${textColorClass}`}>
              No hay resultados disponibles
            </p>
          </div>
        ) : (
          <>
            {matchesToShow?.map((item, index) => {
              return isLiveStream(item) ? (
                <LiveStreamCard
                  key={index}
                  vod={item}
                  defaultImageSrc={defaultImageSrc}
                  bgColorClass={bgColorClass}
                  textColorClass={textColorClass}
                  borderColorClass={borderColorClass}
                />
              ) : (
                <VodCard
                  key={index}
                  vod={item}
                  defaultImageSrc={defaultImageSrc}
                  bgColorClass={bgColorClass}
                  textColorClass={textColorClass}
                  borderColorClass={borderColorClass}
                />
              );
            })}
            {showSeeAll && (
              <a href={seeAllLink} className="block">
                <div className="relative aspect-[4/3] overflow-hidden group transition-all duration-300">
                  <div
                    className={`absolute inset-0 ${bgColorClass} bg-blend-multiply group-hover:bg-blend-overlay transition-all duration-300`}
                    style={{
                      backgroundImage: `url(${defaultImageSrc})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  />
                  <div
                    className={`absolute inset-0 ${bgColorClass} bg-blend-soft-light group-hover:bg-blend-overlay transition-all duration-300`}
                    style={{
                      backgroundImage: `url(${defaultImageSrc})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  />
                  <div
                    className={`absolute inset-0 bg-black bg-opacity-20 group-hover:bg-opacity-10 flex flex-col items-center justify-center p-4 transition-all duration-300 ${textColorClass}`}
                  >
                    <span
                      className={`px-4 py-1 border-2 ${borderColorClass} text-sm rounded-md transition-all duration-300 font-bold`}
                    >
                      Ver todo
                    </span>
                  </div>
                </div>
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CatalogSection;
