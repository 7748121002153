import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_FOOTBALL,
  WEB_ROUTE_BASKETBALL,
  WEB_ROUTE_VODS_MANAGE,
  WEB_ROUTE_PROFILE,
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_LANDING,
} from "@/constants";
import SpinnerLoader from "@/components/SpinnerLoader";
import { useAuthStore } from "@/hooks/useAuthStore";
import Footer from "@/components/Landing/Footer";

export const generalTemplate = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return function WithGeneralTemplate(props: P) {
    const { isAuthenticated, isLiveAuthenticated, logout, isLoading, user } =
      useAuthStore();
    const location = useLocation();
    const isLandingPage = location.pathname === WEB_ROUTE_LANDING;
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
      <div className="bg-black text-gray-900 min-h-screen flex flex-col">
        {/* Sticky Navigation Bar */}
        <nav
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between px-4 md:px-16 py-3 md:pb-4 w-full"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.8) calc(100% - 10px), rgba(0,0,0,0) 100%)",
          }}
        >
          <div className="flex items-center">
            <a href={WEB_ROUTE_LANDING}>
              <img
                src="/img/Imagotipo Horizontal - A Color.svg"
                alt="Logo Baseados"
                className="h-6 md:h-8 w-auto"
              />
            </a>
            {/* Desktop Navigation */}
            {isAuthenticated ? (
              <div className="hidden md:flex ml-12 space-x-6">
                <a
                  href={WEB_ROUTE_HOME}
                  className="text-white font-bold text-sm hover:text-gray-300 transition-colors"
                >
                  Home
                </a>
                <a
                  href={WEB_ROUTE_FOOTBALL}
                  className="text-white font-bold text-sm hover:text-gray-300 transition-colors"
                >
                  Fútbol
                </a>
                <a
                  href={WEB_ROUTE_BASKETBALL}
                  className="text-white font-bold text-sm hover:text-gray-300 transition-colors"
                >
                  Baloncesto
                </a>
              </div>
            ) : (
              !isLandingPage && (
                <div className="hidden md:flex ml-12 space-x-6">
                  <a
                    href={WEB_ROUTE_LANDING}
                    className="text-white font-bold text-sm hover:text-gray-300 transition-colors"
                  >
                    Inicio
                  </a>
                </div>
              )
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMobileMenu}
            className="md:hidden text-white p-2"
            aria-label="Toggle mobile menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>

          {/* Desktop Auth Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            {isAuthenticated ? (
              <>
                {isLiveAuthenticated && (
                  <a
                    href={WEB_ROUTE_VODS_MANAGE}
                    className="px-4 py-1 bg-white text-black text-sm font-bold hover:bg-gray-300 transition-colors duration-200 rounded-sm"
                  >
                    Admin
                  </a>
                )}
                {isLiveAuthenticated && (
                  <a
                    href={
                      isLiveAuthenticated
                        ? WEB_ROUTE_STREAM_PROFILE
                        : WEB_ROUTE_PROFILE
                    }
                    className="px-4 py-1 bg-primary hover:bg-primary-dark text-white text-sm font-bold transition-colors duration-200 rounded-sm"
                  >
                    Mi Stream
                  </a>
                )}
                {!isLiveAuthenticated && isAuthenticated && (
                  <span className="hidden md:inline text-white font-bold text-sm">
                    {user.name}
                  </span>
                )}
                <button
                  onClick={logout}
                  className="px-4 py-1 bg-transparent border border-white text-white text-sm font-bold hover:bg-white hover:text-black transition-colors duration-200 rounded-sm"
                >
                  Cerrar Sesión
                </button>
              </>
            ) : (
              <a
                href="/login"
                className="px-4 py-1 bg-white text-black text-sm font-bold hover:bg-gray-300 transition-colors duration-200 rounded-sm"
              >
                Iniciar Sesión
              </a>
            )}
          </div>
        </nav>

        {/* Mobile Menu Overlay */}
        <div
          className={`fixed inset-0 z-40 bg-black bg-opacity-95 transform ${
            isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out md:hidden`}
        >
          <div className="flex flex-col pt-20 px-4">
            {isAuthenticated ? (
              <>
                <a
                  href={WEB_ROUTE_HOME}
                  className="text-white font-bold text-lg py-3 border-b border-gray-700"
                >
                  Home
                </a>
                <a
                  href={WEB_ROUTE_FOOTBALL}
                  className="text-white font-bold text-lg py-3 border-b border-gray-700"
                >
                  Fútbol
                </a>
                <a
                  href={WEB_ROUTE_BASKETBALL}
                  className="text-white font-bold text-lg py-3 border-b border-gray-700"
                >
                  Baloncesto
                </a>
                {isLiveAuthenticated && (
                  <>
                    <a
                      href={WEB_ROUTE_VODS_MANAGE}
                      className="text-white font-bold text-lg py-3 border-b border-gray-700"
                    >
                      Admin
                    </a>
                    <a
                      href={WEB_ROUTE_STREAM_PROFILE}
                      className="text-white font-bold text-lg py-3 border-b border-gray-700"
                    >
                      Mi Stream
                    </a>
                  </>
                )}
                {!isLiveAuthenticated && (
                  <div className="hidden md:inline text-white font-bold text-lg py-3 border-b border-gray-700">
                    {user.name}
                  </div>
                )}
                <button
                  onClick={logout}
                  className="text-white font-bold text-lg py-3 mt-2"
                >
                  Cerrar Sesión
                </button>
              </>
            ) : (
              <>
                {!isLandingPage && (
                  <a
                    href={WEB_ROUTE_LANDING}
                    className="text-white font-bold text-lg py-3 border-b border-gray-700"
                  >
                    Inicio
                  </a>
                )}
                <a
                  href="/login"
                  className="text-white font-bold text-lg py-3 mt-2"
                >
                  Iniciar Sesión
                </a>
              </>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <SpinnerLoader />
          </div>
        ) : (
          <WrappedComponent {...props} />
        )}

        <Footer />
      </div>
    );
  };
};

export default generalTemplate;
