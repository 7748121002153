import DeviceCompatibility from './DeviceCompatibility';
import ContactTeam from './ContactTeam';

export default function Landing3() {
  return (
    <div className="flex flex-col relative">
      <DeviceCompatibility />
      <ContactTeam />
    </div>
  );
}
