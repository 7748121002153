import React, { useEffect } from "react";

import config from "@config";
import Landing1 from "@components/Landing/Landing1";
import Landing2 from "@components/Landing/Landing2";
import Landing3 from "@components/Landing/Landing3";
import Landing5 from "@components/Landing/Landing5";
import VideoCatalog from "@components/Landing/VideoCatalog";
import generalTemplate from "@/components/HOC/templates/generalTemplate";

const LandingPage: React.FC = () => {
  useEffect(() => {
    document.title = config.public.appName;
  }, []);

  return (
    <div className="bg-black text-gray-900">
      {/* Section 1: DEPORTE BASE A TU ALCANCE */}
      <Landing1 />

      {/* Video Catalog */}
      <VideoCatalog publicEndpoint />

      {/* Section 2: QUÉ OFRECEMOS */}
      <Landing2 />

      {/* First Separator */}
      <div className="relative h-0">
        <img
          src="/img/landing/bar1.png"
          alt="Separator"
          className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 h-14 md:h-auto md:w-full"
        />
      </div>

      {/* Section 3: DESDE TU ORDENADOR, TU TV O TU SMARTPHONE */}
      <Landing3 />

      {/* Second Separator */}
      <div className="relative">
        <img
          src="/img/landing/bar2.png"
          alt="Separator"
          className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 h-14 md:h-auto md:w-full"
        />
      </div>

      {/* Section 4: EQUIPOS */}
      {/* <Landing4 /> */}

      {/* Section 5: ¡APOYA EL DEPORTE BASE! */}
      <Landing5 />
    </div>
  );
};

export default generalTemplate(LandingPage);
