import { WEB_ROUTE_CONTACT_USERS } from "@/constants";

const priceBanners = [
  {
    type: "PPV",
    price: "3",
    cents: "00",
    unit: "€/evento",
    features: [
      "1 evento a elegir",
      "Resolución 1080p [Full HD]",
      "Conectividad con Chromecast",
      "App móvil",
    ],
  },
  {
    type: "PPV",
    price: "3",
    cents: "00",
    unit: "€/evento",
    features: [
      "1 evento a elegir",
      "Resolución 1080p [Full HD]",
      "Conectividad con Chromecast",
      "App móvil",
    ],
  },
  {
    type: "PPV",
    price: "3",
    cents: "00",
    unit: "€/evento",
    features: [
      "1 evento a elegir",
      "Resolución 1080p [Full HD]",
      "Conectividad con Chromecast",
      "App móvil",
    ],
  },
];

export default function Landing5() {
  return (
    <div className="flex flex-col">
      {/* Pricing Section */}
      {/* <section
        className="flex items-center justify-center bg-cover bg-bottom bg-blend-multiply pt-24 pb-10"
        style={{
          backgroundImage: "url('/img/landing/fondo_tv_salon.jpg')",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 md:gap-3 px-4">
          {priceBanners.map((banner, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center w-full md:w-auto mb-10 md:mb-0"
            >
              <div className="absolute -top-4 bg-white rounded-full px-3 py-0.5 z-10 leading-[1.25em]">
                <span className="text-black font-bold text-[10px]">
                  10% DESCUENTO
                </span>
              </div>
              <div className="bg-primary rounded-2xl pt-10 pb-3 px-3 w-full max-w-xs md:w-52 flex flex-col items-center">
                <div className="w-3/4 bg-secondary rounded-full px-4 py-1 mb-6 text-center">
                  <span className="text-black font-extrabold">
                    {banner.type}
                  </span>
                </div>
                <div className="flex items-start gap-1 text-white mb-6">
                  <span className="text-7xl font-extrabold">
                    {banner.price}
                  </span>
                  <div className="flex flex-col">
                    <span className="text-4xl font-extrabold -mb-1.5">
                      '{banner.cents}
                    </span>
                    <span className="text-sm font-bold">{banner.unit}</span>
                    <span className="text-[9px]">IVA INCLUIDO</span>
                  </div>
                </div>
                <div className="bg-primary-2x-light text-black p-3 text-xs text-center w-full rounded-lg">
                  <div className="font-extrabold mb-2">INCLUYE</div>
                  <ul className="list-inside">
                    {banner.features.map((feature, i) => (
                      <li key={i} className="text-[10px] font-semibold">
                        - {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <button className="mt-4 bg-secondary text-white px-5 py-1.5 rounded hover:bg-secondary-dark transition-colors text-xs font-bold">
                Contratar
              </button>
            </div>
          ))}
        </div>
      </section> */}

      {/* Original Content Section */}
      <section className="h-[45%] flex flex-col items-center justify-center">
        <div
          className="flex-1 flex flex-col items-center justify-around bg-cover text-center w-full px-4 pb-14 bg-blend-multiply bg-[center_50%] md:bg-[center_calc(50%-4em)] bg-[length:190%] md:bg-[length:150%]"
          style={{
            backgroundImage: "url('/img/landing/QUINTA PANTALLA - FONDO.jpg')",
            backgroundColor: "rgba(0, 0, 0, 0.3)"
          }}
        >
          <h2 className="text-3xl md:text-4xl font-anton text-secondary my-16 italic uppercase">
            ¡APOYA EL DEPORTE BASE!
          </h2>
          <div className="flex flex-col md:flex-row gap-6 md:gap-24">
            <a
              href={WEB_ROUTE_CONTACT_USERS}
              className="w-full md:w-auto px-6 md:px-4 py-2 bg-secondary text-white hover:bg-secondary-x-light uppercase font-bold text-sm md:text-xs transition-colors duration-200"
            >
              ÚNETE HOY
            </a>
            <a
              href="/login"
              className="w-full md:w-auto px-6 md:px-4 py-2 bg-primary text-white hover:bg-primary-dark uppercase font-bold text-lg md:text-xs transition-colors duration-200"
            >
              CLIENTES LIBERA
            </a>
          </div>
          <img
            src="/img/Imagotipo Horizontal - N.svg"
            alt="Logo Baseados"
            className="w-64 md:w-[24rem] mt-16 mb-8 md:mb-4"
          />
        </div>
      </section>
    </div>
  );
}
