export const HTTP_404 = "Recurso no encontrado";
export const HTTP_401_USER_PASSWORD_INCORRECT =
  "Usuario / Contraseña incorrectos";
export const HTTP_401_INACTIVE_USER = "Usuario sin suscripción";
export const HTTP_401_INVALID_CREDENTIALS =
  "El token de autenticación no es válido. Redirigiendo a la página de login";
export const HTTP_GENERAL_ERROR = "Algo salió mal. Intenta de nuevo en un rato";
export const HTTP_SET_IS_WATCHING_ERROR =
  "Error al actualizar el estado del usuario";

export const USERS_HTTP_400_BAD_REQUEST =
  "Algo salió mal. Intenta de nuevo en un rato";
export const USERS_HTTP_409_USER_ALREADY_EXISTS = "El usuario ya existe";

export const HTTP_409_CONFLICT_ERROR_IN_VIDEO_CHUNK_REQUEST =
  "Estás viendo un video o un directo en otro dispositivo. Por favor, cierra la sesión en ese dispositivo y vuelve a intentarlo.";

export const errorMessagesMap = {
  SSN4003: HTTP_401_INACTIVE_USER,
};
