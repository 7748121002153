import React, { createContext, useContext, useState, useCallback } from "react";
import { API_ROUTE_USERS, API_ROUTE_USER_ME } from "@constants";
import { HTTP_GENERAL_ERROR } from "@constants/network";
import { SUCCESS_MESSAGE_DELAY } from "@constants/feedback";
import config from "@/app.config";
import { useAuthStore } from "./useAuthStore";

interface TUserContent {
  id?: string;
  email?: string;
  picture?: string;
}

interface UserContextType {
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  myUser: TUserContent;
  requestedUser: TUserContent;
  signUp: (email: string, password: string) => Promise<void>;
  getMyData: () => Promise<void>;
  setErrorMessage: (message: string) => void;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [myUser, setMyUser] = useState<TUserContent>({});
  const [requestedUser, setRequestedUser] = useState<TUserContent>({});

  const authStore = useAuthStore();

  const clearMessages = useCallback(() => {
    setTimeout(() => {
      setSuccessMessage("");
    }, SUCCESS_MESSAGE_DELAY);
  }, []);

  const signUp = useCallback(async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        `${config.public.apiUrl}${API_ROUTE_USERS}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getMyData = useCallback(async () => {
    if (!authStore.token) {
      setMyUser({});
      setErrorMessage("");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        `${config.public.apiUrl}${API_ROUTE_USER_ME}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authStore.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = await response.json();
      if (response.ok) {
        setMyUser(data.user);
      } else {
        setErrorMessage(data.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [authStore.token]);

  const updatePassword = useCallback(
    async (password: string, newPassword: string) => {
      try {
        setIsLoading(true);
        setErrorMessage("");
        const response = await fetch(
          `${config.public.apiUrl}${API_ROUTE_USER_ME}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authStore.token}`,
            },
            body: JSON.stringify({ password, newPassword }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update password");
        }
        setSuccessMessage("Contraseña actualizada correctamente");
        clearMessages();
      } catch (error) {
        setErrorMessage("Error al actualizar la contraseña");
      } finally {
        setIsLoading(false);
      }
    },
    [authStore.token, clearMessages]
  );

  const value = {
    isLoading,
    successMessage,
    errorMessage,
    myUser,
    requestedUser,
    signUp,
    getMyData,
    setErrorMessage,
    updatePassword,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserStore = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserStore must be used within a UserProvider");
  }
  return context;
};
