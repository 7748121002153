import { WEB_ROUTE_CONTACT_TEAMS } from "@/constants";

export default function ContactTeam() {
  return (
    <section
      className="w-full p-16 md:p-32 flex flex-col items-center justify-center bg-cover bg-center bg-blend-multiply"
      style={{
        backgroundImage: "url('/img/landing/fondo_ninos_futbol.jpg')",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <h2 className="text-4xl md:text-4xl text-center font-anton text-white mb-8 italic font-bold">
        ¿Quieres ver a tu equipo en Baseados?
      </h2>
      <a
        href={WEB_ROUTE_CONTACT_TEAMS}
        className="bg-secondary hover:bg-secondary-light text-white font-bold py-2 px-4 rounded text-md transition-colors duration-200"
      >
        Contacta con nosotros
      </a>
    </section>
  );
}
