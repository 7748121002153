import { useEffect, useState } from "react";
import config from "@config";
import {
  API_BASE_URL,
  WEB_DISPLAYED_BASE_URL,
  WEB_ROUTE_LIVE_EVENTS,
  API_ROUTE_SPORT_EVENTS_LIST,
} from "@constants";
import { Sport, SportLabels } from "@constants/sports";
import { SUCCESS_MESSAGE_DELAY } from "@constants/feedback";
import { useLiveUserStore } from "@hooks/useLiveUserStore";
import { withLiveAuth } from "@components/HOC/withAuth";
import FileUploader from "@components/FileUploader";
import ActionButton from "@components/ActionButton";
import generalTemplate from "@components/HOC/templates/generalTemplate";
import { useRequest } from "@hooks/useRequest";

interface SportEvent {
  id: string;
  title: string;
  sport: Sport;
  summary?: string;
}

function useSportEvents(sport: Sport) {
  const { data, refetchtInBackground } = useRequest<SportEvent[]>({
    url: `${API_ROUTE_SPORT_EVENTS_LIST}?sport=${sport}`,
    method: "GET",
  });

  return {
    sportEvents: data?.sportEvents || [],
    refetchSportEvents: refetchtInBackground,
  };
}

function CopyToClipboardButton({ textToCopy }: { textToCopy: string }) {
  const [copiedMessage, setCopiedMessage] = useState(false);

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedMessage(true);
          setTimeout(() => setCopiedMessage(false), SUCCESS_MESSAGE_DELAY);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          fallbackCopyTextToClipboard(text);
        });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed"; // Avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        setCopiedMessage(true);
        setTimeout(() => setCopiedMessage(false), SUCCESS_MESSAGE_DELAY);
      } else {
        console.error("Fallback: Unable to copy");
      }
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          copyToClipboard(textToCopy);
        }}
        className="bg-primary px-4 py-2 rounded-r hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary-light focus:ring-opacity-50"
      >
        Copiar
      </button>
      {copiedMessage && (
        <span
          className="absolute right-0 top-[-70%] text-sm text-green-600 animate-fade-in-up
                   md:left-auto md:right-[-15%] md:transform-none md:translate-x-0 md:bottom-0 md:mb-2"
        >
          ¡Copiado!
        </span>
      )}
    </>
  );
}

function StreamProfile() {
  const {
    myUser,
    getMyData,
    updateUser,
    successMessage,
    errorMessage,
    isLoading,
  } = useLiveUserStore();

  useEffect(() => {
    document.title = `${config.public.appName} - Perfil de emisión`;
  }, []);

  const [slug, setSlug] = useState<string>(myUser?.slug || "");
  const [title, setTitle] = useState<string>(myUser?.title || "");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(
    myUser?.thumbnail || ""
  );
  const [summary, setSummary] = useState<string>(myUser?.summary || "");
  const [sport, setSport] = useState<Sport>(myUser?.sport || Sport.SOCCER);
  const [sportEventId, setSportEventId] = useState<string>(
    myUser?.sportEventId || ""
  );
  const [newEventTitle, setNewEventTitle] = useState<string>("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [hasSelectedThumbnail, setHasSelectedThumbnail] = useState(false);
  const [shouldUpdateMyData, setShouldUpdateMyData] = useState(true);
  const { sportEvents, refetchSportEvents } = useSportEvents(sport);
  const selectedSportEvent = sportEvents.find(
    (event) => event.id === sportEventId
  );

  useEffect(() => {
    if (shouldUpdateMyData) {
      getMyData();
      setShouldUpdateMyData(false);
    }
  }, [getMyData, shouldUpdateMyData]);

  useEffect(() => {
    if (myUser?.slug) {
      setSlug(myUser.slug);
    }
  }, [myUser?.slug]);

  useEffect(() => {
    if (myUser?.title) {
      setTitle(myUser.title);
    }
  }, [myUser?.title]);

  useEffect(() => {
    if (myUser?.thumbnail) {
      setThumbnailUrl(myUser.thumbnail);
    }
  }, [myUser?.thumbnail]);

  useEffect(() => {
    if (myUser?.summary) {
      setSummary(myUser.summary);
    }
  }, [myUser?.summary]);

  useEffect(() => {
    if (myUser?.sport) {
      setSport(myUser.sport);
    }
  }, [myUser?.sport]);

  useEffect(() => {
    if (myUser?.sportEventId) {
      setSportEventId(myUser.sportEventId);
    }
  }, [myUser?.sportEventId]);

  useEffect(() => {
    if (selectedSportEvent) {
      setTitle(selectedSportEvent.title);
      setSport(selectedSportEvent.sport);
      setSummary(selectedSportEvent.summary || "");
      setSportEventId(selectedSportEvent.id);
    }
  }, [selectedSportEvent, refetchSportEvents]);

  const isProfileChanged =
    slug !== myUser?.slug ||
    title !== myUser?.title ||
    summary !== myUser?.summary ||
    sport !== myUser?.sport ||
    sportEventId !== myUser?.sportEventId ||
    hasSelectedThumbnail ||
    !!oldPassword ||
    !!newPassword;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const userForm = new FormData();
    userForm.append("slug", slug);
    userForm.append("title", title);
    userForm.append("summary", summary);
    userForm.append("sport", sport);
    if (sportEventId) userForm.append("sportEventId", sportEventId);
    if (thumbnailFile) userForm.append("thumbnail", thumbnailFile);

    if (!sportEventId && !title) {
      setFormError("El título del evento es requerido");
      return;
    }

    if (newPassword && !oldPassword) {
      setPasswordError("Old password is required when setting a new password");
      return;
    }

    if (newPassword && oldPassword) {
      userForm.append("oldPassword", oldPassword);
      userForm.append("newPassword", newPassword);
    }

    try {
      await updateUser(userForm);
      if (!sportEventId) refetchSportEvents();
      setShouldUpdateMyData(true);
      setFormError("");
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setHasSelectedThumbnail(false);
      setOldPassword("");
      setNewPassword("");
      setPasswordError("");
    }
  };

  return (
    <div className="relative w-full mx-auto mt-0 p-4 pb-16 pt-24 text-white">
      <form onSubmit={handleSubmit} className="max-w-xl mx-auto md:pb-0 pb-16">
        {/* EMAIL */}
        <div className="mb-4 flex items-center">
          <label htmlFor="email" className="w-1/4 mr-8 text-right">
            Email
          </label>
          <div className="w-3/4">
            <input
              type="email"
              id="email"
              autoComplete="email"
              value={myUser?.email || ""}
              className="w-full px-3 py-2 border rounded"
              disabled
            />
          </div>
        </div>

        {/* LIVE STATUS */}
        <div className="mb-4 flex items-center">
          <label htmlFor="isLive" className="w-1/4 mr-8 text-right">
            Emisión
          </label>
          <input
            type="text"
            id="isLive"
            value={myUser?.isLive ? "Live" : "Offline"}
            disabled
            className={`w-3/4 px-3 py-2 border rounded ${
              myUser?.isLive
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          />
        </div>

        {/* SPORT EVENT */}
        <div className="mb-4 flex items-center">
          <label htmlFor="sportEvent" className="w-1/4 mr-8 text-right">
            Evento
          </label>
          <select
            id="sportEvent"
            value={sportEventId}
            onChange={(e) => {
              setSportEventId(e.target.value);
              setNewEventTitle("");
            }}
            disabled={!!newEventTitle}
            className="w-3/4 px-3 py-2 border rounded bg-gray-300 text-black disabled:bg-gray-400 disabled:opacity-50"
          >
            <option value="">Nuevo evento</option>
            {sportEvents.map((event) => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>
        </div>

        {/* TITLE */}
        {!sportEventId && (
          <div className="mb-4 flex items-center">
            <label htmlFor="title" className="w-1/4 mr-8 text-right">
              Título del evento
              {title ? "*" : <span className="text-red-500">*</span>}
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-3/4 px-3 py-2 border rounded bg-gray-300 text-black disabled:bg-gray-500"
              disabled={!!sportEventId}
            />
          </div>
        )}

        {/* SPORT */}
        <div className="mb-4 flex items-center">
          <label htmlFor="sport" className="w-1/4 mr-8 text-right">
            Deporte
          </label>
          <select
            id="sport"
            value={sport}
            onChange={(e) => setSport(e.target.value as Sport)}
            className="w-3/4 px-3 py-2 border rounded bg-gray-300 text-black disabled:bg-gray-500"
            disabled={!!sportEventId}
          >
            {Object.values(Sport).map((sportValue) => (
              <option key={sportValue} value={sportValue}>
                {SportLabels[sportValue]}
              </option>
            ))}
          </select>
        </div>

        {/* THUMBNAIL */}
        <div className="mb-4 flex items-start">
          <label htmlFor="thumbnail" className="w-1/4 mr-8 text-right">
            Miniatura
          </label>
          <div className="w-3/4 px-3 py-2 flex">
            {thumbnailUrl && (
              <div className="flex-grow w-full md:w-1/2 lg:w-1/4 mr-8">
                <img
                  src={API_BASE_URL + thumbnailUrl}
                  alt="Thumbnail"
                  className="w-full h-auto rounded"
                />
              </div>
            )}
            <FileUploader
              name="thumbnail"
              accept="image/*"
              file={thumbnailFile}
              setFile={setThumbnailFile}
              message="Seleccionar"
              onChange={() => setHasSelectedThumbnail(true)}
            />
          </div>
        </div>

        {/* SLUG */}
        <div className="mb-4">
          <label htmlFor="slug" className="block mb-2">
            Enlace a la emisión
            {slug ? "*" : <span className="text-red-500">*</span>}
          </label>
          <div className="flex items-center">
            <span
              className="inline-block mr-1 pr-1 pl-3 py-2 border rounded bg-gray-300 text-black"
              style={{ userSelect: "none" }}
            >
              {WEB_DISPLAYED_BASE_URL}
              {WEB_ROUTE_LIVE_EVENTS}/
            </span>
            <input
              type="text"
              id="slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              className="flex-grow pl-1 py-2 border rounded bg-gray-300 text-black"
            />
          </div>
        </div>

        {/* SUMMARY */}
        <div className="mb-4">
          <label htmlFor="summary" className="block mb-2">
            Descripción de la emisión
          </label>
          <textarea
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            className="w-full px-3 py-2 border rounded bg-gray-300 text-black disabled:bg-gray-500"
            disabled={!!sportEventId}
          />
        </div>

        {/* STREAM SERVER */}
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <label className="block mb-2">Servidor de emisión</label>
            <div className="group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-500 cursor-help"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="absolute hidden group-hover:block bg-white border-4 border-gray-700 rounded p-2 z-10 -mt-2 left-1/4 w-1/2 min-w-[200px]">
                <div className="mb-2">
                  En OBS, accede a "Ajustes" {">"} "Emisión" y pega la Clave de
                  retransmisión:
                </div>
                <img
                  src="/img/obs_instructions_2.png"
                  alt="Stream key info"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>

          <div className="flex relative">
            <input
              type="text"
              id="streamServer"
              value="rtmp://live.baseados.tv:1935/live"
              disabled
              className="w-full px-3 py-2 border rounded-l bg-gray-400 text-black"
            />
            <CopyToClipboardButton textToCopy="rtmp://live.baseados.tv:1935/live" />
          </div>
        </div>

        {/* STREAM KEY */}
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <label htmlFor="streamUrl" className="block mr-2">
              Clave de retransmisión
            </label>
            <div className="group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-500 cursor-help"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="absolute hidden group-hover:block bg-white border-4 border-gray-700 rounded p-2 z-10 -mt-2 left-1/4 w-1/2 min-w-[200px]">
                <div className="mb-2">
                  En OBS, accede a "Ajustes" {">"} "Emisión" y pega la Clave de
                  retransmisión:
                </div>
                <img
                  src="/img/obs_instructions.png"
                  alt="Stream key info"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
          <div className="flex relative">
            <input
              type="text"
              id="streamUrl"
              value={`${myUser.email}?key=${myUser.streamAuthKey}`}
              readOnly
              className="w-full px-3 py-2 border rounded-l bg-gray-400 text-black"
            />
            <CopyToClipboardButton
              textToCopy={`${myUser.email}?key=${myUser.streamAuthKey}`}
            />
          </div>
        </div>

        {/* RESET PASSWORD */}
        <div className="mb-4">
          <label className="block mb-2">Cambiar contraseña</label>
          <div className="flex space-x-2">
            <input
              type="password"
              placeholder="Contraseña actual"
              autoComplete="current-password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="w-1/2 px-3 py-2 border rounded bg-gray-300 text-black"
            />
            <input
              type="password"
              placeholder="Nueva contraseña"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-1/2 px-3 py-2 border rounded bg-gray-300 text-black"
            />
          </div>
          {passwordError && (
            <p className="text-red-500 mt-1">{passwordError}</p>
          )}
        </div>

        {formError && <p className="text-red-500 mt-1">{formError}</p>}

        <ActionButton
          isLoading={isLoading}
          errorMessage={errorMessage}
          successMessage={successMessage}
          enabled={isProfileChanged}
          mandatoryFields={[slug]}
        >
          Actualizar perfil
        </ActionButton>
      </form>
    </div>
  );
}

export default withLiveAuth(generalTemplate(StreamProfile));
