import React, { useEffect } from "react";
import config from "@config";

import CatalogSection from "@components/Landing/CatalogSection";
import { generalTemplate } from "@components/HOC/templates/generalTemplate";
import { withAuth } from "@components/HOC/withAuth";
import { useVodsList } from "@hooks/useVodsList";
import { Sport } from "@constants/sports";
import { IVodsListResponse } from "@hooks/useVodsList";
import { useLiveList } from "@hooks/useLiveList";
import { ILiveListResponse } from "@hooks/useLiveList";
import { ILiveStream } from "@hooks/useLiveStream";
import { IVod } from "@hooks/useVod";
import { NO_RESULTS } from "@constants/images";

const EMPTY_LIST: (IVod | ILiveStream)[] = [];

const FootballPage: React.FC = () => {
  useEffect(() => {
    document.title = `Fútbol - ${config.public.appName}`;
  }, []);

  const { vodsList }: IVodsListResponse = useVodsList(Sport.SOCCER);

  const {
    liveList,
    isLoading: isLiveLoading,
    errorMessage: liveErrorMessage,
  }: ILiveListResponse = useLiveList(Sport.SOCCER);

  const resultsList = React.useMemo(
    () => [...(liveList || EMPTY_LIST), ...(vodsList || EMPTY_LIST)],
    [liveList, vodsList]
  );

  return (
    <div className="pt-16 flex-grow">
      {liveErrorMessage && (
        <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          {liveErrorMessage}
        </div>
      )}
      <CatalogSection
        title="Fútbol"
        color="primary"
        textColor="white"
        defaultImageSrc="/img/landing/fondo_futbol_1.jpg"
        noResultsImageSrc={NO_RESULTS}
        matches={resultsList}
        isLoading={isLiveLoading}
      />
    </div>
  );
};

export default withAuth(generalTemplate(FootballPage));
