import { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useRequest } from "@hooks/useRequest";

interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
  toEmail: string;
}

interface FormErrors {
  email?: string;
  phone?: string;
  contact?: string;
}

export default function ContactForm({
  toEmail,
  route,
}: {
  toEmail: string;
  route: string;
}) {
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    phone: "",
    message: "",
    toEmail: toEmail,
  });
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const { isLoading: isSubmitting, errorMessage } = useRequest({
    url: shouldSubmit ? route : "",
    method: "POST",
    body: shouldSubmit ? formData : null,
  });

  // Watch for the response and show appropriate toast
  useEffect(() => {
    if (shouldSubmit && !isSubmitting && errorMessage !== undefined) {
      if (errorMessage) {
        toast.error(
          "Error al enviar el mensaje. Por favor, inténtalo de nuevo."
        );
      } else {
        toast.success("Mensaje enviado correctamente");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          toEmail: "fedelll@gmail.com",
        });
        setFormErrors({});
      }
      setShouldSubmit(false);
    }
  }, [isSubmitting, errorMessage, shouldSubmit]);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return email === "" || emailRegex.test(email);
  };

  const isValidPhone = (phone: string): boolean => {
    // Accepts formats: +34666666666, 666666666, 666-666-666
    const phoneRegex = /^(\+34|0034|34)?[6-9]\d{8}$/;
    return phone === "" || phoneRegex.test(phone.replace(/-/g, ""));
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!formData.email && !formData.phone) {
      errors.contact =
        "Debes proporcionar al menos un método de contacto: email o teléfono";
    }

    if (formData.email && !isValidEmail(formData.email)) {
      errors.email = "Por favor, introduce un email válido";
    }

    if (formData.phone && !isValidPhone(formData.phone)) {
      errors.phone = "Por favor, introduce un número de teléfono válido";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setShouldSubmit(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear specific field error when user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }

    // Clear contact error if either email or phone is being filled
    if ((name === "email" || name === "phone") && formErrors.contact) {
      setFormErrors((prev) => ({
        ...prev,
        contact: undefined,
      }));
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <Toaster
        position="top-center"
        gutter={20}
        toastOptions={{ style: { marginTop: "3rem" } }}
      />
      <h1 className="text-4xl font-bold text-center mb-8 text-white">
        Contacta con nosotros
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-white"
          >
            Nombre <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-black px-3"
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-white"
          >
            Email {!formData.phone && <span className="text-red-500">*</span>}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:border-primary focus:ring-primary text-black px-3 ${
              formErrors.email ? "border-red-500" : "border-gray-300"
            }`}
          />
          {formErrors.email && (
            <p className="mt-1 text-sm text-red-500">{formErrors.email}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-white"
          >
            Teléfono{" "}
            {!formData.email && <span className="text-red-500">*</span>}
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:border-primary focus:ring-primary text-black px-3 ${
              formErrors.phone ? "border-red-500" : "border-gray-300"
            }`}
          />
          {formErrors.phone && (
            <p className="mt-1 text-sm text-red-500">{formErrors.phone}</p>
          )}
        </div>

        {formErrors.contact && (
          <div className="text-red-500 text-sm">{formErrors.contact}</div>
        )}

        <div>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-white"
          >
            Mensaje <span className="text-red-500">*</span>
          </label>
          <textarea
            id="message"
            name="message"
            required
            value={formData.message}
            onChange={handleChange}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-black px-3"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full px-4 py-2 bg-primary hover:bg-primary-dark text-white font-bold rounded-md transition-colors duration-200 disabled:opacity-50"
        >
          {isSubmitting ? "Enviando..." : "Enviar mensaje"}
        </button>
      </form>
    </div>
  );
}
