import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "@config";
import VideoPlayer from "@components/VideoPlayer";
import { API_BASE_URL, API_ROUTE_VOD } from "@constants";
import useVod, { IVodResponse } from "@hooks/useVod";
import { useAuthStore } from "@hooks/useAuthStore";
import { withAuth } from "@components/HOC/withAuth";
import SpinnerLoader from "@components/SpinnerLoader";
import Modal from "@components/Modal";
import { generalTemplate } from "@/components/HOC/templates/generalTemplate";

function Vod() {
  const { id = "" } = useParams();
  const { vod, isLoading, errorMessage }: IVodResponse = useVod(id);
  const [videoPlayerErrorMessage, setVideoPlayerErrorMessage] = useState<
    string | null
  >(null);

  const authStore = useAuthStore();
  useEffect(() => {
    authStore.setIsWatching();
  }, [authStore]);

  useEffect(() => {
    document.title = `${config.public.appName} - ${vod?.title || "VOD"}`;
  }, [vod?.title]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden mt-16">
      <main className="flex-1 overflow-x-hidden overflow-y-auto">
        {videoPlayerErrorMessage && (
          <Modal isOpen>
            <div>
              <h2 className="text-2xl font-bold text-primary-dark">
                Error al cargar el video
              </h2>
              <div className="mt-2 text-gray-600">
                {videoPlayerErrorMessage}
              </div>
            </div>
          </Modal>
        )}
        {errorMessage && (
          <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {errorMessage}
          </div>
        )}
        {!vod || isLoading ? (
          <div className="flex-1 flex justify-center items-center">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="container mx-auto px-4 py-6">
            <div className="w-full">
              <div className="bg-gray-900 rounded-lg overflow-hidden">
                <VideoPlayer
                  src={`${API_BASE_URL}${API_ROUTE_VOD}${vod.id}/index.m3u8?token=${authStore.token}`}
                  setErrorMessage={setVideoPlayerErrorMessage}
                  videoId={vod.id}
                />
              </div>
              <div className="mt-4 bg-gray-600 rounded-lg p-4 shadow">
                <h1 className="text-2xl font-bold text-primary-light">
                  {vod.title}
                </h1>
                <div className="mt-2 text-white">{vod.summary}</div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default withAuth(generalTemplate(Vod));
