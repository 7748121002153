import React, { useEffect } from "react";
import config from "@config";
import Landing1 from "@components/Landing/Landing1";
import VideoCatalog from "@components/Landing/VideoCatalog";
import ContactTeam from "@/components/Landing/ContactTeam";
import { generalTemplate } from "@/components/HOC/templates/generalTemplate";
import { withAuth } from "@/components/HOC/withAuth";

const HomePage: React.FC = () => {
  useEffect(() => {
    document.title = config.public.appName;
  }, []);

  return (
    <>
      {/* Section 1: DEPORTE BASE A TU ALCANCE */}
      <Landing1 />

      {/* Video Catalog */}
      <VideoCatalog />

      {/* Section: Contact Team */}
      <ContactTeam />
    </>
  );
};

export default withAuth(generalTemplate(HomePage));
