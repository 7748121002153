export default function DeviceCompatibility() {
  return (
    <section
      className="w-full p-16 flex flex-col md:flex-row items-center justify-center md:justify-center bg-cover bg-center bg-blend-multiply [background-position:center] md:[background-position:center_-6em]"
      style={{
        backgroundImage: "url('/img/landing/TERCERA PANTALLA - FONDO.jpg')",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      }}
    >
      <div className="max-w-xl mb-8 md:mb-0 flex-1 flex flex-col items-center">
        <h2 className="text-4xl md:text-4xl text-center font-anton text-secondary italic uppercase max-w-sm">
          DESDE TU ORDENADOR, TU TV O TU SMARTPHONE
        </h2>
        <p className="text-lg md:text-sm text-white text-center md:text-center mt-4 bg-black bg-opacity-70 p-2 md:p-0 md:bg-transparent max-w-sm">
          Compatible con Google Chromecast. Ya puedes disfrutar de Baseados en
          tu Smart TV.
        </p>
        <div className="flex items-center md:text-smgap-2 mt-4 text-white text-base bg-black bg-opacity-70 p-2 md:p-0 md:bg-transparent">
          <span>Google Chromecast</span>{" "}
          <img
            src="/img/landing/chromecast_icon.png"
            alt="Chromecast"
            className="h-4 w-4 brightness-200 ml-2"
          />
        </div>
      </div>

      <div className="flex space-x-4 md:space-x-16 mt-auto md:mt-0">
        <div className="flex flex-col items-center">
          <img
            src="/img/landing/TERCERA PANTALLA 1.png"
            alt="iPhone App"
            className="h-48 md:h-[16rem] object-contain mb-4 md:mb-8"
          />
          <img
            src="/img/landing/appstore.png"
            alt="Descarga en App Store"
            className="h-8 cursor-pointer hover:opacity-80"
          />
        </div>

        <div className="flex flex-col items-center">
          <img
            src="/img/landing/TERCERA PANTALLA 2.png"
            alt="Android App"
            className="h-48 md:h-[16rem] object-contain mb-4 md:mb-8"
          />
          <img
            src="/img/landing/playstore.png"
            alt="Descarga en Google Play"
            className="h-8 cursor-pointer hover:opacity-80"
          />
        </div>
      </div>
    </section>
  );
} 