// API ROUTES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_ROUTE_LOGIN = "/session/login";
export const API_ROUTE_LOGOUT = "/session/logout";
export const API_ROUTE_SESSION = "/session";
export const API_ROUTE_LIVE_LOGOUT = "/live/session/logout";

export const API_ROUTE_USERS = "/users";
export const API_ROUTE_USER_ME = "/users/me";
export const API_ROUTE_LIVE_USER_ME = "/live/users/me";

export const API_ROUTE_LIVE_LIST = "/liveusers";
export const API_ROUTE_LIVE_LIST_PUBLIC = "/public/liveusers";
export const API_ROUTE_LIVE_STREAM = "/liveusers/";

export const API_ROUTE_VODS_LIST = "/vods";
export const API_ROUTE_VODS_LIST_PUBLIC = "/public/vods";
export const API_ROUTE_VOD = "/vods/";
export const API_ROUTE_VODS_MANAGE = "/live/vods";
export const API_ROUTE_VOD_CREATE = "/vods/create";

export const API_ROUTE_CONTACT_USERS = "/public/contact/users";
export const API_ROUTE_CONTACT_TEAMS = "/public/contact/teams";

export const API_ROUTE_SPORT_EVENTS_LIST = "/live/sportevents";
export const API_ROUTE_SPORT_EVENT = "/live/sportevents/";

// WEB ROUTES
// Public routes
export const WEB_DISPLAYED_BASE_URL =
  process.env.REACT_APP_WEB_DISPLAYED_BASE_URL;
export const WEB_ROUTE_LANDING = "/";
export const WEB_ROUTE_LOGIN = "/login";
export const WEB_ROUTE_HOME = "/home";
export const WEB_ROUTE_FOOTBALL = "/futbol";
export const WEB_ROUTE_BASKETBALL = "/baloncesto";
export const WEB_ROUTE_LIVE_EVENTS = "/live";
export const WEB_ROUTE_VODS = "/vods";
export const WEB_ROUTE_PPV = "/ppv";
export const WEB_ROUTE_PROFILE = "/profile";
export const WEB_ROUTE_CONTACT_USERS = "/contact/users";
export const WEB_ROUTE_CONTACT_TEAMS = "/contact/teams";
export const WEB_ROUTE_LEGAL_NOTICE = "/aviso-legal";
export const WEB_ROUTE_COOKIES_POLICY = "/cookies";
export const WEB_ROUTE_PRIVACY_POLICY = "/privacidad";
// Admin routes
export const WEB_ROUTE_STREAM_PROFILE = "/live-profile";
export const WEB_ROUTE_VOD_CREATE = "/vods/create";
export const WEB_ROUTE_VODS_MANAGE = "/vods/manage";

export const WEB_ADMIN_ROUTES = [
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_VOD_CREATE,
  WEB_ROUTE_VODS_MANAGE,
];

// NETWORKING
export const DEFAULT_TIME_TO_REDIRECT = 5000;
export const HTTP_STATUS_CODE_500 = "500";
