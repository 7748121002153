import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "@config";
import {
  WEB_ADMIN_ROUTES,
  WEB_ROUTE_LANDING,
  WEB_ROUTE_PROFILE,
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_HOME,
} from "@constants";
import { IMAGOTYPE_SECONDARY_COLOR, LOGIN_BACKGROUND } from "@constants/images";
import { useAuthStore } from "@hooks/useAuthStore";
import { useUserStore } from "@hooks/useUserStore";
import SpinnerLoader from "@components/SpinnerLoader";
import FormLogin from "@components/FormLogin";

function isAdminRoute(referrer: string) {
  if (!referrer) return false;
  return WEB_ADMIN_ROUTES.reduce((isReferrerAdminRoute, route) => {
    return isReferrerAdminRoute || referrer.startsWith(route);
  }, false);
}

function useNavigateAfterLogin() {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authStore.isLiveAuthenticated) {
      const referrer = location.state?.referrer || WEB_ROUTE_HOME;
      navigate(referrer);
    } else if (authStore.isAuthenticated) {
      const referrer =
        location.state?.referrer === WEB_ROUTE_STREAM_PROFILE
          ? WEB_ROUTE_PROFILE
          : isAdminRoute(location.state?.referrer) ||
            location.state?.referrer === WEB_ROUTE_LANDING
          ? WEB_ROUTE_HOME
          : location.state?.referrer || WEB_ROUTE_HOME;
      navigate(referrer);
    }
  }, [
    authStore.isLiveAuthenticated,
    authStore.isAuthenticated,
    navigate,
    location,
  ]);
}

function Login() {
  const authStore = useAuthStore();
  const userStore = useUserStore();

  useEffect(() => {
    document.title = `Accede a tu cuenta de ${config.public.appName}`;
  }, []);

  useNavigateAfterLogin();

  return (
    <section className="bg-white">
      <div className="flex h-screen">
        {/* Left pane */}
        <div className="hidden lg:flex items-center justify-center flex-1 bg-primary text-black h-screen">
          <img src={LOGIN_BACKGROUND} alt="Login" />
        </div>

        {/* Right pane */}
        <div className="w-full bg-white lg:w-1/2 flex items-center justify-center">
          <div className="max-w-md w-full p-6">
            <Link
              to={WEB_ROUTE_LANDING}
              className="flex flex-col items-center pb-0"
            >
              <img className="" src={IMAGOTYPE_SECONDARY_COLOR} alt="Logo" />
            </Link>
            {authStore.isLoading || userStore.isLoading ? (
              <SpinnerLoader className="text-white text-center" />
            ) : (
              <div className="mt-8">
                <FormLogin />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
