import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_VOD_CREATE,
  WEB_ROUTE_VODS_MANAGE,
  WEB_ROUTE_LANDING,
  WEB_ROUTE_HOME,
} from "@constants";
import { IMAGOTYPE_PRIMARY_NEGATIVE } from "@constants/images";
import { useAuthStore } from "@hooks/useAuthStore";

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const authStore = useAuthStore();

  useEffect(() => {
    setIsOpen(false);
  }, [location, setIsOpen]);

  const closeSidebar = () => setIsOpen(false);

  const activeClass = "bg-primary-light bg-opacity-25 text-white border-white";
  const inactiveClass =
    "border-primary-x-dark text-white hover:bg-primary-light hover:bg-opacity-25 hover:text-white";

  return (
    <div className="flex z-10">
      {/* Backdrop */}
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={closeSidebar}
      />

      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-primary-dark lg:translate-x-0 lg:static lg:inset-0 ${
          isOpen ? "translate-x-0 ease-out" : "-translate-x-full ease-in"
        }`}
      >
        <Link to={WEB_ROUTE_LANDING} onClick={closeSidebar}>
          <div className="flex items-center justify-center mt-4">
            <div className="flex items-center">
              <img
                className="h-8"
                src={IMAGOTYPE_PRIMARY_NEGATIVE}
                alt="Logo Baseados"
              />
            </div>
          </div>
        </Link>

        <div className="flex items-center mt-4">
          <div className="flex-1 border border-t-0 border-primary-x-dark"></div>
        </div>

        <nav>
          {/* HOME */}
          <div>
            <Link
              className={`flex items-center px-6 py-2 duration-200 border-l-4 text-sm ${
                location.pathname === WEB_ROUTE_HOME
                  ? activeClass
                  : inactiveClass
              }`}
              to={WEB_ROUTE_HOME}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
              </svg>
              <div className="flex items-center justify-between w-full">
                <span className="mx-4">Inicio</span>
              </div>
            </Link>
          </div>

          {/* Admin Section */}
          {authStore.isLiveAuthenticated && (
            <div className="px-6 py-2 text-xs font-semibold text-primary-light uppercase">
              Admin
            </div>
          )}

          {/* STREAM PROFILE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_STREAM_PROFILE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_STREAM_PROFILE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Perfil del Directo</span>
                </div>
              </Link>
            </div>
          )}

          {/* VOD CREATE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_VOD_CREATE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_VOD_CREATE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Subir Video</span>
                </div>
              </Link>
            </div>
          )}

          {/* VOD MANAGE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_VODS_MANAGE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_VODS_MANAGE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm3.293 1.293a1 1 0 011.414 0L8 8.586l1.293-1.293a1 1 0 111.414 1.414L9.414 10l1.293 1.293a1 1 0 01-1.414 1.414L8 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L6.586 10 5.293 8.707a1 1 0 010-1.414zM14 9a1 1 0 100 2h4a1 1 0 100-2h-4zm0 4a1 1 0 100 2h4a1 1 0 100-2h-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Gestionar Videos</span>
                </div>
              </Link>
            </div>
          )}

          {/* LOGOUT */}
          <div
            onClick={() => {
              closeSidebar();
              authStore.logout();
            }}
            className={`flex items-center px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${inactiveClass}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex items-center justify-between w-full">
              <span className="mx-4">Log out</span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
