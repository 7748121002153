import React from "react";
import { WEB_ROUTE_FOOTBALL, WEB_ROUTE_BASKETBALL } from "@constants";
import { IVodsListResponse, useVodsList } from "@hooks/useVodsList";
import { Sport } from "@constants/sports";
import { ILiveListResponse } from "@hooks/useLiveList";
import { useLiveList } from "@hooks/useLiveList";
import { NO_RESULTS, NO_RESULTS_SECONDARY } from "@constants/images";
import CatalogSection from "./CatalogSection";

interface VideoCatalogProps {
  publicEndpoint?: boolean;
}

const VideoCatalog: React.FC<VideoCatalogProps> = (props) => {
  const { publicEndpoint } = props;

  const { vodsList: footballVodsList }: IVodsListResponse = useVodsList(
    Sport.SOCCER,
    publicEndpoint
  );

  const {
    liveList: footballLiveList,
    isLoading: footballIsLoading,
  }: ILiveListResponse = useLiveList(Sport.SOCCER, publicEndpoint);

  const { vodsList: basketballVodsList }: IVodsListResponse = useVodsList(
    Sport.BASKET,
    publicEndpoint
  );

  const {
    liveList: basketballLiveList,
    isLoading: basketballIsLoading,
  }: ILiveListResponse = useLiveList(Sport.BASKET, publicEndpoint);

  const footballList = React.useMemo(
    () => [
      ...(footballLiveList || []),
      ...(footballVodsList || []).slice(
        0,
        Math.max(0, 4 - (footballLiveList?.length || 0))
      ),
    ],
    [footballLiveList, footballVodsList]
  );

  const basketballList = React.useMemo(
    () => [
      ...(basketballLiveList || []),
      ...(basketballVodsList || []).slice(
        0,
        Math.max(0, 4 - (basketballLiveList?.length || 0))
      ),
    ],
    [basketballLiveList, basketballVodsList]
  );

  return (
    <div className="bg-black mb-16">
      <CatalogSection
        title="Fútbol"
        color="primary"
        textColor="white"
        defaultImageSrc="/img/landing/fondo_futbol_1.jpg"
        noResultsImageSrc={NO_RESULTS}
        matches={footballList}
        maxMatches={5}
        seeAll
        seeAllLink={WEB_ROUTE_FOOTBALL}
        isLoading={footballIsLoading}
      />
      <CatalogSection
        title="Baloncesto"
        color="secondary"
        textColor="black"
        defaultImageSrc="/img/landing/fondo_basket_1.jpg"
        noResultsImageSrc={NO_RESULTS_SECONDARY}
        matches={basketballList}
        maxMatches={5}
        seeAll
        seeAllLink={WEB_ROUTE_BASKETBALL}
        isLoading={basketballIsLoading}
      />
    </div>
  );
};

export default VideoCatalog;
