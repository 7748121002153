import React from "react";
import generalTemplate from "@/components/HOC/templates/generalTemplate";

const LegalNoticePage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 my-24 text-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">AVISO LEGAL</h1>

        <p className="mb-6">
          El presente documento constituye el Aviso Legal y las Condiciones de
          Uso que regulan el acceso, navegación y uso de la web de la sociedad
          mercantil, "BREAK FREE TELECOM, S.L." ( "BASEADOS") ubicada en la URL
          www.baseados.es ("Sitio Web") por lo que se insta a la lectura del
          mismo para conocimiento de sus términos.
        </p>

        <p className="mb-6">
          El acceso a esta página web y la navegación y la utilización de la
          misma por parte del usuario implica la aceptación expresa y sin
          reservas de todos los términos del presente Aviso Legal, desplegándose
          la misma validez y eficacia entre las partes que la derivada de un
          acuerdo o contrato celebrado y suscrito entre las partes en cualquiera
          de las formas admitidas en derecho. Si una vez leído el presente aviso
          no está de acuerdo con los términos expuestos, absténgase de acceder o
          navegar por la Web, puesto que el uso del sitio está condicionado al
          cumplimiento y observancia de todo lo dispuesto en el presente Aviso.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          1. DATOS DE LA COMPRESA. CONTACTO:
        </h2>
        <p className="mb-4">
          "BREAK FREE TELECOM, S.L." ("BASEADOS") es una sociedad mercantil con
          N.I.F. B-01798230, y con domicilio en Avenida de la Constitución,
          número 8, CP 30008 Murcia, encontrándose inscrita en el Registro
          Mercantil de Murcia al Tomo 3461, Folio 76, inscripción 1 con Hoja
          MU-103101
        </p>
        <p className="mb-6">
          Para comunicarse con "BASEADOS" sírvase dirigirse a la dirección de
          correo electrónico: hola@baseados.tv o en la dirección de correo
          postal anteriormente descrita.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          2. OBJETO Y ÁMBITO DE APLICACIÓN
        </h2>
        <p className="mb-4">
          Las presentes condiciones regulan el acceso y disfrute de los
          contenidos ofrecidos por "BASEADOS" a través del Sitio Web, así como
          la utilización de los mismos por parte de los usuarios. No obstante,
          "BASEADOS" se reserva el derecho a modificar la presentación,
          configuración y contenido del Sitio Web, así como también las
          condiciones requeridas para su acceso y/o utilización.
        </p>
        <p className="mb-4">
          El acceso y utilización de los contenidos y servicios tras las
          modificaciones o los cambios en las condiciones suponen la aceptación
          de las mismas.
        </p>
        <p className="mb-6">
          Antes de acceder a los servicios específicos ofertados por BASEADOS,
          el Usuario, esto es, la persona que acceda, navegue, utilice o
          participe en los servicios y actividades, gratuitas, desarrolladas en
          el Sitio Web, deberá leer atentamente las Condiciones Generales
          creadas a tal efecto por BASEADOS. El acceso a los servicios, implica
          la aceptación de las Condiciones Generales que los regulen en la
          versión publicada por BASEADOS en el momento en que se produzca dicha
          contratación. El acceso, navegación y uso del Sitio Web conllevan y
          supone la aceptación por el Usuario del presente Aviso Legal y las
          Condiciones Generales que incluye.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. ACCESO Y SEGURIDAD. USO COMPARTIDO DE CUENTAS.
        </h2>
        <p className="mb-4">
          El acceso por parte del usuario a la INFORMACIÓN sobre los
          "Contenidos" (partidos de futbol base retransmitidos en directo y en
          diferido) y sobre los servicios proporcionados por BASEADOS es libre y
          tiene carácter gratuito, si bien el acceso y disfrute de los
          Contenidos ofrecidos por BASEADOS, están sujetos a registro y
          condicionados a que el usuario sea usuario de LIBERA TELECOM (MARCA DE
          "BREAK FREE TELECOM, S.L.").
        </p>
        <p className="mb-4">
          Queda prohibido el acceso al Sitio Web por parte de menores de edad,
          salvo que cuenten con la autorización previa y expresa de sus padres,
          tutores o representantes legales, los cuales serán considerados como
          responsables de los actos que lleven a cabo los menores a su cargo,
          conforme a la normativa vigente. En todo caso, se presumirá que el
          acceso realizado por un menor al Sitio Web se ha realizado con
          autorización previa y expresa de sus padres, tutores o representantes
          legales.
        </p>
        <p className="mb-4">
          El acceso y navegación a través del Sitio Web no requiere registro,
          sin embargo, para acceder a la visualización, utilización y/o acceso a
          los Servicios, BASEADOS requerirá la condición de usuario de LIBERA
          TELECOM y el alta previa de este Sitio Web mediante la selección por
          parte del Usuario de identificador y contraseña. Una vez el Usuario se
          haya dado de alta a través de este Sitio Web o de cualquier otro medio
          habilitado al efecto por BASEADOS, el Usuario será considerado Usuario
          en los términos establecidos en las Condiciones Generales.
        </p>
        <p className="mb-4">
          Los Usuarios deben abstenerse de realizar cualquier uso no autorizado
          de su cuenta. No está permitido el uso compartido de las cuentas de
          usuarios o de Usuarios de BASEADOS, así como el intercambio,
          revelación, difusión o uso colectivo de las credenciales (incluyendo
          nombre de usuario y contraseña) del Servicio.
        </p>
        <p className="mb-6">
          BASEADOS se reserva la facultad de requerir a quien publique
          información y/o facilite el intercambio o uso compartido de las
          cuentas, la eliminación de todos los datos relativos a las cuentas de
          BASEADOS, y ello sin perjuicio de otro tipo de acciones que pudiesen
          ser ejercitadas acorde a derecho para la defensa de sus intereses.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          4. PROPIEDAD INTELECTUAL E INDUSTRIAL
        </h2>
        <p className="mb-4">
          BASEADOS ostenta todas las autorizaciones necesarias para la
          explotación de todos los derechos de propiedad intelectual e
          industrial asociados a este Sitio Web. Cualquier explotación de un
          elemento que esté incluido dentro del Sitio Web o de los Servicios, ya
          sea con o sin ánimo de lucro, debe contar con la autorización expresa
          y previa de BASEADOS o, en su caso, de su legítimo titular de
          derechos.
        </p>
        <p className="mb-6">
          BASEADOS respeta todos los derechos de propiedad intelectual e
          industrial de terceros. Si en algún momento entiende que está siendo
          infringido por BASEADOS algún derecho, por favor, le rogamos contacte
          con nosotros en la dirección hola@baseados.tv y analizaremos el
          asunto.
        </p>

        <h1 className="text-3xl font-bold mt-12 mb-8">
          CONDICIONES GENERALES DE CONTRATACIÓN
        </h1>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          1. RESPONSABLE DEL SERVICIO
        </h2>
        <p className="mb-4">
          La entidad responsable del Sitio Web es "BREAK FREE TELECOM, S.L." (en
          adelante, "BASEADOS") una sociedad mercantil con N.I.F. B-01798230, y
          con domicilio en Avenida de la Constitución, número 8, CP 30008
          Murcia, encontrándose inscrita en el Registro Mercantil de Murcia al
          Tomo 3461, Folio 76, inscripción 1 con Hoja MU-103101
        </p>
        <p className="mb-6">
          Para comunicarse con "BASEADOS" sírvase dirigirse a la dirección de
          correo electrónico: hola@baseados.tv o en la dirección de correo
          postal anteriormente descrita.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. SERVICIO</h2>
        <p className="mb-4">
          Las presentes condiciones generales de contratación, junto con el
          Aviso Legal, tienen por objeto regular expresamente las condiciones
          aplicables a la utilización por parte de los usuarios (USUARIOS DE
          LIBERA TELECOM) de los contenidos ofrecidos por parte de BASEADOS a
          través de su sitio web www.BASEADOS.es y www.BASEADOS.tv.
        </p>
        <p className="mb-4">
          Los contenidos/servicios que BASEADOS pone a disposición de los
          Usuarios son:
        </p>
        <div className="pl-6 mb-6">
          <p>
            - Retransmisión de partidos de futbol base en directo o en diferido
          </p>
          <p>
            -Creación de una cuenta de usuario que permite al Usuario de LIBERA
            TELECOM acceder a las distintas secciones y colecciones del catálogo
            de BASEADOS.
          </p>
          <p>-Suscripción a la Newsletter de BASEADOS.</p>
        </div>

        <p className="mb-6">
          Estas Condiciones Generales permanecerán en vigor y serán válidas
          durante todo el tiempo que estén accesibles a través del Sitio Web,
          sin perjuicio de que BASEADOS se reserva el derecho a modificar las
          Condiciones Generales, así como cualquiera de los textos legales que
          se encuentren expuestos en el Sitio Web, lo cual será previamente
          notificado. En todo caso, el acceso al Sitio Web tras su modificación,
          inclusión y/o sustitución, implica la aceptación de los mismos por
          parte del usuario.
        </p>
      </div>
    </div>
  );
};

export default generalTemplate(LegalNoticePage);
