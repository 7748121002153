import React from "react";
import generalTemplate from "@/components/HOC/templates/generalTemplate";

const CookiesPolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 my-24 text-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">POLÍTICA DE COOKIES</h1>

        <p className="mb-6">
          Este sitio web utiliza cookies esenciales para garantizar su funcionamiento adecuado y cookies de seguimiento para comprender cómo interactúa con él. Las siguientes cookies se utilizan:
        </p>

        <div className="pl-6 mb-6">
          <p className="mb-4">
            <span className="font-semibold">Cookie de autenticación (necesaria):</span> Usada para mantenerte conectado
          </p>
          <p className="mb-4">
            <span className="font-semibold">Cookie de autenticación de administrador (necesaria):</span> Usada para acceder al panel de administración
          </p>
        </div>
      </div>
    </div>
  );
};

export default generalTemplate(CookiesPolicyPage); 