import { API_ROUTE_VOD } from "@constants";
import { useRequest } from "./useRequest";
import { Sport } from "@/constants/sports";

export interface IVodVersion {
  resolution: string;
  bitrate?: string;
  size?: string;
  visible: boolean;
}

export interface IVod {
  id: string;
  title?: string;
  summary?: string;
  thumbnail: string;
  size: string;
  visible: boolean;
  versions?: IVodVersion[];
  status: "SENT" | "STARTED" | "FINISHED";
  sport: Sport;
}

export interface IVodResponse {
  vod: IVod | null;
  isLoading: boolean;
  errorMessage: string;
}

function useVod(id: string): IVodResponse {
  const { data, isLoading, errorMessage } = useRequest<IVod>({
    url: `${API_ROUTE_VOD}${id}`,
    method: "GET",
  });

  return { vod: data?.vod, isLoading, errorMessage };
}

export default useVod;
