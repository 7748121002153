import {
  WEB_ROUTE_LEGAL_NOTICE,
  WEB_ROUTE_COOKIES_POLICY,
  WEB_ROUTE_PRIVACY_POLICY,
} from "@/constants";
import React from "react";
import {
  FaWhatsapp,
  FaPhone,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <footer className="w-full bg-secondary text-black mt-auto pt-4">
      <div className="flex flex-col md:flex-row md:justify-around gap-8 md:gap-0 mb-8 text-center md:text-left">
        <div>
          <h4 className="font-bold text-sm md:text-xs mb-1">Apps</h4>
          <p className="text-xs md:text-[10px] text-gray-700">En desarrollo</p>
        </div>
        <div>
          <h4 className="font-bold text-sm md:text-xs mb-2 text-center">
            Contacto
          </h4>
          <div className="flex gap-1 justify-center md:justify-center">
            <a
              href="https://wa.me/34684412140"
              className="w-7 h-7 bg-green-500 rounded-full flex items-center justify-center text-white hover:bg-green-600 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp size={16} />
            </a>
            <a
              href="tel:+34868289754"
              className="w-7 h-7 bg-gray-800 rounded-full flex items-center justify-center text-white hover:bg-gray-900 transition-colors"
            >
              <FaPhone size={12} />
            </a>
            <a
              href="mailto:hola@baseados.tv"
              className="w-7 h-7 bg-red-500 rounded-full flex items-center justify-center text-white hover:bg-red-600 transition-colors"
            >
              <FaEnvelope size={14} />
            </a>
          </div>
        </div>
        <div>
          <h4 className="font-bold text-sm md:text-xs mb-2 text-center">
            Redes sociales
          </h4>
          <div className="flex gap-1 justify-center md:justify-center">
            <a
              href="https://facebook.com/liberatelecom"
              className="w-7 h-7 bg-blue-600 rounded-full flex items-center justify-center text-white hover:bg-blue-700 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF size={14} />
            </a>
            <a
              href="https://instagram.com/liberatelecom"
              className="w-7 h-7 bg-black rounded-full flex items-center justify-center text-white hover:bg-gray-900 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={14} />
            </a>
            <a
              href="https://tiktok.com/@liberatelecom"
              className="w-7 h-7 bg-gray-500 rounded-full flex items-center justify-center text-white hover:bg-gray-600 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok size={14} />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-primary text-center text-white text-xs py-2">
        <div className="mb-2">
          <a href={WEB_ROUTE_LEGAL_NOTICE} className="hover:text-white">
            Aviso legal
          </a>
          <span className="mx-2">·</span>
          <a href={WEB_ROUTE_COOKIES_POLICY} className="hover:text-white">
            Política de cookies
          </a>
          <span className="mx-2">·</span>
          <a href={WEB_ROUTE_PRIVACY_POLICY} className="hover:text-white">
            Política de privacidad
          </a>
        </div>
        <p>Baseados - All Rights Reserved - 2025 - Powered by Liberatelecom</p>
      </div>
    </footer>
  );
};

export default Footer;
