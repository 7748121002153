import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { trackPageView } from "./utils/analytics";

import Index from "@pages/Index";
import Login from "@pages/Login";
import Profile from "@pages/Profile";
import LiveList from "@pages/LiveList";
import LiveStream from "@pages/LiveStream";
import VodsList from "@pages/VodsList";
import Vod from "@pages/Vod";
import StreamProfile from "@pages/live/StreamProfile";
import VodCreate from "@pages/live/VodCreate";
import VodsManage from "@pages/live/VodsManage";
import VodDetailsManage from "@pages/live/VodDetailsManage";
import Home from "@pages/Home";
import Football from "@pages/Football";
import Basketball from "@pages/Basketball";
import ContactUsers from "@pages/ContactUsers";
import ContactTeams from "@pages/ContactTeams";
import LegalNotice from "@/pages/LegalNotice";
import CookiesPolicy from "@/pages/CookiesPolicy";
import PrivacyPolicy from "@/pages/PrivacyPolicy";

import {
  WEB_ROUTE_LANDING,
  WEB_ROUTE_LOGIN,
  WEB_ROUTE_PROFILE,
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_LIVE_EVENTS,
  WEB_ROUTE_VODS,
  WEB_ROUTE_VOD_CREATE,
  WEB_ROUTE_VODS_MANAGE,
  WEB_ROUTE_HOME,
  WEB_ROUTE_FOOTBALL,
  WEB_ROUTE_BASKETBALL,
  WEB_ROUTE_CONTACT_USERS,
  WEB_ROUTE_CONTACT_TEAMS,
  WEB_ROUTE_LEGAL_NOTICE,
  WEB_ROUTE_COOKIES_POLICY,
  WEB_ROUTE_PRIVACY_POLICY,
} from "@constants";

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <Routes>
      {/* PUBLIC ROUTES */}
      <Route path={WEB_ROUTE_LANDING} element={<Index />} />
      <Route path={WEB_ROUTE_LOGIN} element={<Login />} />
      <Route path={WEB_ROUTE_HOME} element={<Home />} />
      <Route path={WEB_ROUTE_FOOTBALL} element={<Football />} />
      <Route path={WEB_ROUTE_BASKETBALL} element={<Basketball />} />
      <Route path={WEB_ROUTE_PROFILE} element={<Profile />} />
      <Route path={WEB_ROUTE_LEGAL_NOTICE} element={<LegalNotice />} />
      <Route path={WEB_ROUTE_COOKIES_POLICY} element={<CookiesPolicy />} />
      <Route path={WEB_ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route
        path={`${WEB_ROUTE_LIVE_EVENTS}/:slug`}
        element={<LiveStream />}
      />
      <Route path={`${WEB_ROUTE_VODS}/:id`} element={<Vod />} />
      {/* ADMIN ROUTES */}
      <Route path={WEB_ROUTE_STREAM_PROFILE} element={<StreamProfile />} />
      <Route path={WEB_ROUTE_VOD_CREATE} element={<VodCreate />} />
      <Route path={WEB_ROUTE_VODS_MANAGE} element={<VodsManage />} />
      <Route
        path={`${WEB_ROUTE_VODS_MANAGE}/:id`}
        element={<VodDetailsManage />}
      />
      <Route path={WEB_ROUTE_CONTACT_USERS} element={<ContactUsers />} />
      <Route path={WEB_ROUTE_CONTACT_TEAMS} element={<ContactTeams />} />
    </Routes>
  );
}

function AppRoutes() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default AppRoutes;
