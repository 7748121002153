import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "@config";
import { WEB_ROUTE_VODS_MANAGE, API_ROUTE_SPORT_EVENTS_LIST } from "@constants";
import { useAuthStore } from "@hooks/useAuthStore";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withLiveAuth } from "@components/HOC/withAuth";
import FileUploader from "@components/FileUploader";
import ActionButton from "@/components/ActionButton";
import { Sport } from "@/constants/sports";
import { useRequest } from "@hooks/useRequest";
import {
  getSignedUrl,
  uploadToGCS,
  finalizeUpload,
  getThumbnailSignedUrl,
  finalizeThumbnailUpload,
} from "@/services/uploadService";

const NAVIGATION_TIMEOUT_MS = config.app.timeouts.navigationDelay;

interface SportEvent {
  id: string;
  title: string;
  sport: Sport;
  summary?: string;
}

function useSportEvents(sport: Sport) {
  const { data, refetchtInBackground } = useRequest<SportEvent[]>({
    url: `${API_ROUTE_SPORT_EVENTS_LIST}?sport=${sport}`,
    method: "GET",
  });

  return {
    sportEvents: data?.sportEvents || [],
    refetchSportEvents: refetchtInBackground,
  };
}

function VodCreate() {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${config.public.appName} - Subir VOD`;
  }, []);

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [vodFile, setVodFile] = useState<File | null>(null);
  const [vodThumbFile, setVodThumbFile] = useState<File | null>(null);
  const [sport, setSport] = useState(Sport.SOCCER);
  const [sportEventId, setSportEventId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formError, setFormError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [thumbnailError, setThumbnailError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { sportEvents, refetchSportEvents } = useSportEvents(sport);
  const selectedSportEvent = sportEvents.find(
    (event) => event.id === sportEventId
  );

  useEffect(() => {
    if (selectedSportEvent) {
      setSport(selectedSportEvent.sport);
      setSummary(selectedSportEvent.summary || "");
      setTitle(selectedSportEvent.title);
      setSportEventId(selectedSportEvent.id);
    }
  }, [selectedSportEvent]);

  // Handle thumbnail file selection with validation
  const handleThumbnailChange = (file: File | null) => {
    setThumbnailError("");
    if (file) {
      // Check if the file is a PNG
      if (file.type !== "image/png") {
        setThumbnailError("La miniatura debe ser un archivo PNG");
        setVodThumbFile(null);
        return;
      }
      setVodThumbFile(file);
    } else {
      setVodThumbFile(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!vodFile) {
      return;
    }

    if (!title) {
      setFormError("El título del video es requerido");
      return;
    }

    // Validate thumbnail is PNG if provided
    if (vodThumbFile && vodThumbFile.type !== "image/png") {
      setThumbnailError("La miniatura debe ser un archivo PNG");
      return;
    }

    try {
      setIsLoading(true);
      setUploadProgress(0);
      setStatusMessage("Por favor espera mientras se sube el archivo. No cierres esta página.");
      setErrorMessage("");
      setSuccessMessage("");

      // Generate unique filenames for the video and thumbnail
      const videoExtension = vodFile.name.split(".").pop();
      const uniqueVideoFilename = `${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 15)}.${videoExtension}`;

      // Step 1: Get signed URL and vodId for video upload
      const { signedUrl: videoSignedUrl, vodId } = await getSignedUrl(
        uniqueVideoFilename,
        vodFile.type,
        {
          title,
          summary,
          sport,
          sportEventId: sportEventId || undefined,
          thumbnail: vodThumbFile ? vodThumbFile.name : undefined,
        },
        authStore.token
      );

      // Step 2: Upload video to GCS with progress tracking
      await uploadToGCS(videoSignedUrl, vodFile, (progress) => {
        setUploadProgress(progress);
        setStatusMessage(`Subiendo video: ${progress.toFixed(0)}%`);
      });

      // Step 3: Upload the thumbnail if provided
      if (vodThumbFile) {
        setUploadProgress(0);
        setStatusMessage("Preparando para subir la miniatura...");

        const thumbExtension = vodThumbFile.name.split(".").pop();
        const thumbnailFilename = `${Date.now()}-${Math.random()
          .toString(36)
          .substring(2, 15)}.${thumbExtension}`;

        // Get signed URL for thumbnail upload using the new endpoint
        const { signedUrl: thumbSignedUrl } = await getThumbnailSignedUrl(
          thumbnailFilename,
          "image/png", // Ensure content type is image/png as required by backend
          vodId,
          authStore.token
        );

        // Upload thumbnail to GCS with progress tracking
        await uploadToGCS(thumbSignedUrl, vodThumbFile, (progress) => {
          setUploadProgress(progress);
          setStatusMessage(`Subiendo miniatura: ${progress.toFixed(0)}%`);
        });

        // Finalize the thumbnail upload using the new endpoint
        await finalizeThumbnailUpload(
          thumbnailFilename,
          vodId,
          authStore.token
        );
      }

      await finalizeUpload(uniqueVideoFilename, vodId, authStore.token);

      // Set success message
      setSuccessMessage("Video subido correctamente");
      setIsLoading(false);
      
      // Navigate after success
      setTimeout(() => {
        if (!sportEventId) refetchSportEvents();
        navigate(WEB_ROUTE_VODS_MANAGE);
      }, NAVIGATION_TIMEOUT_MS);
      
    } catch (error) {
      console.error(error);
      setErrorMessage("Error al subir el video");
      setIsLoading(false);
    }
  };

  // Handle overlay close
  const handleOverlayClose = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div className="relative w-full h-full mx-auto mt-8 mb-8 md:mb-0 overflow-y-auto max-h-screen p-4">
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
        {/* SPORT EVENT */}
        <div className="mb-8 flex items-center">
          <label htmlFor="sportEvent" className="mr-8">
            Evento
          </label>
          <select
            id="sportEvent"
            value={sportEventId}
            onChange={(e) => {
              setSportEventId(e.target.value);
            }}
            className="w-3/4 px-3 py-2 border rounded"
          >
            <option value="">Nuevo evento</option>
            {sportEvents.map((event) => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>
        </div>

        {/* TITLE */}
        <div className="mb-8 flex items-center">
          <label htmlFor="title" className="mr-8">
            Título{title ? "*" : <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-3/4 px-3 py-2 border rounded"
          />
        </div>

        {/* FILE */}
        <div className="mb-8 flex items-baseline">
          <label htmlFor="vodFile" className="mr-8">
            Archivo{vodFile ? "*" : <span className="text-red-500">*</span>}
          </label>
          <div className="w-3/4">
            <FileUploader
              name="vodFile"
              accept="video/*"
              file={vodFile}
              setFile={setVodFile}
              message="Seleccionar video"
              changeMessage="Cambiar video"
            />
          </div>
        </div>

        {/* THUMBNAIL */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Miniatura (PNG)
          </label>
          <FileUploader
            name="thumbnail"
            accept="image/png"
            message="Seleccionar miniatura"
            changeMessage="Cambiar miniatura"
            file={vodThumbFile}
            setFile={setVodThumbFile}
            onChange={handleThumbnailChange}
          />
          {thumbnailError && (
            <p className="text-red-500 text-xs italic mt-1">{thumbnailError}</p>
          )}
        </div>

        {/* SUMMARY */}
        <div className="mb-8">
          <label htmlFor="summary" className="block mb-2">
            Descripción
          </label>
          <textarea
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            disabled={!!sportEventId}
            rows={5}
          />
        </div>

        {/* SPORT */}
        <div className="mb-8 flex items-center">
          <label htmlFor="sport" className="mr-8">
            Deporte
          </label>
          <select
            id="sport"
            value={sport}
            onChange={(e) => setSport(e.target.value as Sport)}
            className="w-3/4 px-3 py-2 border rounded"
            disabled={!!sportEventId}
          >
            <option value={Sport.SOCCER}>Fútbol</option>
            <option value={Sport.BASKET}>Baloncesto</option>
          </select>
        </div>

        {/* SUBMIT BUTTON WITH OVERLAY */}
        <div className="flex justify-center items-center gap-4">
          <ActionButton
            isLoading={isLoading}
            errorMessage={errorMessage}
            successMessage={successMessage}
            mandatoryFields={
              errorMessage || formError || thumbnailError
                ? [title, vodFile]
                : []
            }
            enabled={!isLoading}
            progressValue={uploadProgress}
            statusMessage={statusMessage}
            onClose={handleOverlayClose}
          >
            Subir Video
          </ActionButton>
        </div>
      </form>
    </div>
  );
}

export default withLiveAuth(loggedInTemplate(VodCreate));
