import { API_ROUTE_LIVE_LIST, API_ROUTE_LIVE_LIST_PUBLIC } from "@/constants";
import { useRequest } from "./useRequest";
import { ILiveStream } from "./useLiveStream";
import { Sport } from "@/constants/sports";

export interface ILiveListResponse {
  liveList: ILiveStream[] | null;
  isLoading: boolean;
  errorMessage: string;
}

export function useLiveList(
  sport: Sport,
  publicEndpoint?: boolean
): ILiveListResponse {
  const { data, isLoading, errorMessage } = useRequest<ILiveStream[]>({
    url: `${
      publicEndpoint ? API_ROUTE_LIVE_LIST_PUBLIC : API_ROUTE_LIVE_LIST
    }?sport=${sport}`,
    method: "GET",
  });

  return { liveList: data?.liveUsers, isLoading, errorMessage };
}

export function useIsAnyoneLive(sport: Sport, publicEndpoint?: boolean) {
  const { liveList } = useLiveList(sport, publicEndpoint);
  return liveList && liveList.length > 0;
}
