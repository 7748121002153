import React from "react";
import generalTemplate from "@/components/HOC/templates/generalTemplate";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 my-24 text-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">
          POLÍTICA DE PRIVACIDAD
        </h1>

        <h2 className="text-xl font-semibold mb-4">
          Información básica sobre cómo tratamos tus datos:
        </h2>
        <div className="pl-6">
          <p>
            • Responsable: BREAK FREE TELECOM, S.L." siempre actuará como
            Responsable del Tratamiento.
          </p>
          <p>• Finalidad: Utilizamos tus datos para:</p>
          <p>• Mantener la relación para la prestación de nuestros Servicios</p>
          <p>
            • La gestión, administración, prestación y mejora del Servicio
            ofrecido por BASEADOS.
          </p>
          <p>
            • El envío de newsletters o información sobre nuestras novedades o
            sobre nuestros Servicios.
          </p>
          <p>
            • Legitimación: Ejecución de un contrato y el consentimiento del
            interesado en caso de nuestros usuarios, e interés legítimo en otros
            casos.
          </p>
          <p>
            • Destinatarios: No se cederán los datos sin consentimiento expreso,
            salvo obligación legal o en los casos descritos más abajo
          </p>
          <p>
            • Derechos: Acceder, rectificar y suprimir los datos, así como otros
            derechos, como se explica en la información adicional.
          </p>
        </div>
      </div>
    </div>
  );
};

export default generalTemplate(PrivacyPolicyPage);
