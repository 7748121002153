import { WEB_ROUTE_CONTACT_USERS } from "@/constants";

export default function Landing1() {
  return (
    <section className="h-[90vh] flex flex-col items-center bg-cover bg-center text-center relative">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover object-center"
        playsInline
        autoPlay
        muted
        loop
        preload="auto"
        poster="/img/placeholder_video.png"
      >
        <source src="/video/output.webm" type="video/webm" />
        <source src="/video/output.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex-1 flex flex-col justify-center mt-24 md:mt-0 relative">
        <h1 className="text-5xl md:text-7xl font-bold text-white mb-4 font-anton tracking-wider px-4 md:px-0 max-w-2xl mx-auto italic">
          El deporte base a tu alcance
        </h1>

        <div className="flex flex-col md:flex-row gap-4 md:gap-16 mt-4 px-4 md:px-0 justify-center">
          <a
            href={WEB_ROUTE_CONTACT_USERS}
            className="w-full md:w-auto px-4 py-2 bg-secondary hover:bg-secondary-x-light text-white uppercase font-bold text-sm rounded-sm transition-colors duration-200"
          >
            ÚNETE HOY
          </a>
          <a
            href="/login"
            className="w-full md:w-auto px-4 py-2 bg-primary hover:bg-primary-dark text-white uppercase font-bold text-sm rounded-sm transition-colors duration-200"
          >
            CLIENTES LIBERA
          </a>
        </div>
      </div>
    </section>
  );
}
