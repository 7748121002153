import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "@hooks/useAuthStore";
import { WEB_ROUTE_LOGIN } from "@/constants";
import { generalTemplate } from "./templates/generalTemplate";
import SpinnerLoader from "@/components/SpinnerLoader";
const WrappedLoadingSpinner = generalTemplate(SpinnerLoader);

export function withAuth<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithAuth: React.FC<P> = (props) => {
    const { isAuthenticated, isLoading } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        navigate("/login", {
          replace: true,
          state: { referrer: location.pathname },
        });
      }
    }, [isAuthenticated, navigate, location, isLoading]);

    if (!isAuthenticated) {
      return <WrappedLoadingSpinner />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
}

export function withLiveAuth<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithLiveAuth: React.FC<P> = (props) => {
    const { isLiveAuthenticated, isLoading } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isLoading && !isLiveAuthenticated) {
        navigate(WEB_ROUTE_LOGIN, {
          replace: true,
          state: { referrer: location.pathname },
        });
      }
    }, [isLiveAuthenticated, navigate, location, isLoading]);

    if (!isLiveAuthenticated) {
      return null; // or a loading spinner
    }

    return <WrappedComponent {...props} />;
  };

  return WithLiveAuth;
}
