import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import config from "@config";
import {
  API_BASE_URL,
  API_ROUTE_VODS_MANAGE,
  API_ROUTE_SPORT_EVENTS_LIST,
} from "@constants";
import { SUCCESS_MESSAGE_DELAY } from "@constants/feedback";
import useManageVod, { IManageVodResponse } from "@hooks/live/useManageVod";
import { useAuthStore } from "@hooks/useAuthStore";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withLiveAuth } from "@components/HOC/withAuth";
import Toggle from "@components/Toggle";
import Modal from "@components/Modal";
import FileUploader from "@components/FileUploader";
import ActionButton from "@components/ActionButton";
import { Sport } from "@/constants/sports";
import { useRequest } from "@hooks/useRequest";
import {
  uploadToGCS,
  getThumbnailSignedUrl,
  finalizeThumbnailUpload,
} from "@/services/uploadService";

interface SportEvent {
  id: string;
  title: string;
  sport: Sport;
  summary?: string;
}

function useSportEvents(sport: Sport) {
  const { data, refetchtInBackground } = useRequest<SportEvent[]>({
    url: `${API_ROUTE_SPORT_EVENTS_LIST}?sport=${sport}`,
    method: "GET",
  });

  return {
    sportEvents: data?.sportEvents || [],
    refetchSportEvents: refetchtInBackground,
  };
}

function VodDetailsManage() {
  const { id = "" } = useParams();
  const authStore = useAuthStore();

  useEffect(() => {
    document.title = `${config.public.appName} - Editar VOD`;
  }, []);

  const {
    vod,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    refetchtInBackground,
    deleteVod,
  }: IManageVodResponse = useManageVod(id);
  const [title, setTitle] = useState<string>(vod?.title || "");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(
    vod?.thumbnail || ""
  );
  const [summary, setSummary] = useState<string>(vod?.summary || "");
  const [visible, setVisible] = useState<boolean>(vod?.visible || false);
  const [sport, setSport] = useState<Sport>(vod?.sport || Sport.SOCCER);
  const [sportEventId, setSportEventId] = useState<string>(
    vod?.sportEventId || ""
  );
  const [size, setSize] = useState<string>(vod?.size || "");
  const [dateCreated, setDateCreated] = useState<string>(
    vod?.dateCreated || ""
  );
  const [hasSelectedThumbnail, setHasSelectedThumbnail] = useState(false);
  const [hasInfoChanged, setHasInfoChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [thumbnailError, setThumbnailError] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { sportEvents, refetchSportEvents } = useSportEvents(sport);
  const selectedSportEvent = sportEvents.find(
    (event) => event.id === sportEventId
  );

  useEffect(() => {
    if (vod) {
      setTitle(vod.title);
      setSummary(vod.summary);
      setThumbnailUrl(vod.thumbnail);
      setVisible(vod.visible);
      setSport(vod.sport);
      setSize(vod.size);
      setDateCreated(vod.dateCreated);
      setHasInfoChanged(false);
    }
  }, [vod]);

  useEffect(() => {
    if (vod?.sportEventId && sportEvents.length > 0) {
      const event = sportEvents.find((event) => event.id === vod.sportEventId);
      if (event) {
        setSportEventId(vod.sportEventId);
      }
    }
  }, [vod?.sportEventId, sportEvents]);

  useEffect(() => {
    refetchSportEvents();
  }, [sport, refetchSportEvents]);

  useEffect(() => {
    if (selectedSportEvent) {
      setSport(selectedSportEvent.sport);
      setSummary(selectedSportEvent.summary || "");
    }
  }, [selectedSportEvent]);

  const handleThumbnailChange = (file: File | null) => {
    setThumbnailError("");
    if (file) {
      if (file.type !== "image/png") {
        setThumbnailError("La miniatura debe ser un archivo PNG");
        setThumbnailFile(null);
        setHasSelectedThumbnail(false);
        return;
      }
      setThumbnailFile(file);
      setHasSelectedThumbnail(true);
    } else {
      setThumbnailFile(null);
      setHasSelectedThumbnail(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (thumbnailFile && thumbnailFile.type !== "image/png") {
      setThumbnailError("La miniatura debe ser un archivo PNG");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage("");
      setUploadProgress(0);

      // First, update the VOD metadata without thumbnail
      let response = await fetch(
        `${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.token}`,
          },
          body: JSON.stringify({
            title,
            summary,
            visible,
            sport,
            sportEventId: sportEventId || undefined,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error al actualizar el Video");
      }

      // If there's a thumbnail file, upload it separately
      if (thumbnailFile) {
        setUploadProgress(0);
        const thumbExtension = thumbnailFile.name.split(".").pop();
        const thumbnailFilename = `${Date.now()}-${Math.random()
          .toString(36)
          .substring(2, 15)}.${thumbExtension}`;

        try {
          // Get signed URL for thumbnail upload using the new endpoint
          const { signedUrl: thumbSignedUrl } = await getThumbnailSignedUrl(
            thumbnailFilename,
            "image/png", // Ensure content type is image/png as required by backend
            id, // Use the current VOD ID
            authStore.token
          );

          // Upload thumbnail to GCS with progress tracking
          await uploadToGCS(thumbSignedUrl, thumbnailFile, (progress) => {
            setUploadProgress(progress);
          });

          // Finalize the thumbnail upload using the new endpoint
          await finalizeThumbnailUpload(thumbnailFilename, id, authStore.token);
        } catch (thumbnailError) {
          console.error("Error uploading thumbnail:", thumbnailError);
          throw new Error(
            thumbnailError instanceof Error
              ? thumbnailError.message
              : "Error al subir la miniatura"
          );
        }
      }

      setSuccessMessage("Video actualizado correctamente");
      if (!sportEventId) refetchSportEvents();
      refetchtInBackground();
    } catch (error) {
      console.error("Error updating VOD:", error);
      setErrorMessage(
        error instanceof Error ? error.message : "Error al actualizar el Video"
      );
    } finally {
      setIsLoading(false);
      setHasSelectedThumbnail(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, SUCCESS_MESSAGE_DELAY);
    }
  };

  useEffect(() => {
    setHasInfoChanged(
      title !== vod?.title ||
        summary !== vod?.summary ||
        visible !== vod?.visible ||
        sport !== vod?.sport ||
        sportEventId !== vod?.sportEventId ||
        hasSelectedThumbnail
    );
  }, [title, summary, visible, sport, sportEventId, hasSelectedThumbnail, vod]);

  const handleDelete = useCallback(async () => {
    console.log("Deleting VOD...");
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    await deleteVod();
    setIsLoading(false);
  }, [deleteVod, setIsLoading]);

  return (
    <div className="relative w-full h-full mx-auto mt-8 mb-8 md:mb-0 overflow-y-auto max-h-screen p-4">
      <Modal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen}>
        <div className="mb-8">¿Estás seguro de querer borrar el video?</div>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 border border-gray-500 hover:bg-gray-300 rounded"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Mejor no
          </button>
          <button
            type="button"
            className="ml-4 px-4 py-2 bg-red-300 text-black rounded hover:bg-red-700 hover:text-white"
            onClick={handleDelete}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block mr-2"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
            </svg>
            Borrar
          </button>
        </div>
      </Modal>
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
        {/* SPORT EVENT */}
        <div className="mb-8 flex items-center">
          <label htmlFor="sportEvent" className="mr-8">
            Evento
          </label>
          <select
            id="sportEvent"
            value={sportEventId}
            onChange={(e) => {
              setSportEventId(e.target.value);
            }}
            className="w-3/4 px-3 py-2 border rounded"
          >
            <option value="">Nuevo evento</option>
            {sportEvents.map((event) => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>
        </div>

        {/* TITLE */}
        <div className="mb-8 flex items-center">
          <label htmlFor="title" className="mr-8">
            Título{title ? "*" : <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-3/4 px-3 py-2 border rounded"
          />
        </div>

        {/* SIZE AND DATE CREATED */}
        <div className="mb-8 bg-gray-100 p-4 rounded-lg shadow-sm">
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-gray-600">Tamaño</p>
              <p className="text-md font-medium">{size || "N/A"}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Fecha de creación</p>
              <p className="text-md font-medium">
                {dateCreated
                  ? new Date(dateCreated).toLocaleDateString("es-ES", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* THUMBNAIL */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Miniatura (PNG)
          </label>
          <div className="flex items-start">
            {thumbnailUrl && !thumbnailFile && (
              <div className="md:w-1/2 lg:w-1/4 mr-8">
                <img
                  src={API_BASE_URL + thumbnailUrl}
                  alt="Thumbnail"
                  className="h-full rounded"
                />
              </div>
            )}
            {thumbnailFile && (
              <div className="md:w-1/2 lg:w-1/4 mr-8">
                <img
                  src={URL.createObjectURL(thumbnailFile)}
                  alt="New Thumbnail"
                  className="h-full rounded"
                />
              </div>
            )}
            <div>
              <FileUploader
                name="thumbnail"
                accept="image/png"
                message="Seleccionar miniatura"
                changeMessage="Cambiar miniatura"
                file={thumbnailFile}
                setFile={setThumbnailFile}
                onChange={handleThumbnailChange}
              />
              {thumbnailError && (
                <p className="text-red-500 text-xs italic mt-1">
                  {thumbnailError}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* UPLOAD PROGRESS */}
        {isLoading && thumbnailFile && (
          <div className="mb-8">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-primary h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-center mt-2">{uploadProgress}%</p>
          </div>
        )}

        {/* SUMMARY */}
        <div className="mb-8">
          <label htmlFor="summary" className="block mb-2">
            Descripción
          </label>
          <textarea
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            disabled={!!sportEventId}
          />
        </div>

        {/* VISIBILITY, SPORT, AND DELETE */}
        <div className="mb-8 flex items-center justify-between">
          <Toggle
            checked={visible}
            onClick={() => setVisible((checked) => !checked)}
            checkedLabel="Visible"
            uncheckedLabel="Oculto"
          />

          <div className="flex items-center">
            <p className="mr-4">Deporte</p>
            <select
              value={sport}
              onChange={(e) => setSport(e.target.value as Sport)}
              className="px-3 py-2 border rounded"
              disabled={!!sportEventId}
            >
              <option value={Sport.SOCCER}>Fútbol</option>
              <option value={Sport.BASKET}>Baloncesto</option>
            </select>
          </div>

          <button
            type="button"
            className="ml-4 px-4 py-2 bg-red-300 text-black rounded hover:bg-red-700 hover:text-white"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block mr-2"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
            </svg>
            Eliminar el Video
          </button>
        </div>

        <ActionButton
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          enabled={hasInfoChanged}
          mandatoryFields={[title]}
        >
          Actualizar VOD
        </ActionButton>
      </form>
    </div>
  );
}

export default withLiveAuth(loggedInTemplate(VodDetailsManage));
