import { useState, useEffect } from "react";
import config from "@config";

type OverlayStatus = "idle" | "loading" | "success" | "error";

const SUCCESS_TIMEOUT_MS = config.app.timeouts.successOverlay;
const ERROR_TIMEOUT_MS = config.app.timeouts.errorOverlay;

interface ActionButtonProps {
  children: string | React.ReactNode;
  isLoading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  enabled?: boolean;
  mandatoryFields?: any[];
  progressValue?: number;
  statusMessage?: string;
  onClose?: () => void;
}

export default function ActionButton(props: ActionButtonProps) {
  const {
    children,
    isLoading,
    successMessage,
    errorMessage,
    enabled = true,
    mandatoryFields,
    progressValue = 0,
    statusMessage = "Procesando...",
    onClose,
  } = props;

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStatus, setOverlayStatus] = useState<OverlayStatus>("idle");
  const [overlayMessage, setOverlayMessage] = useState("");

  useEffect(() => {
    if (isLoading) {
      setShowOverlay(true);
      setOverlayStatus("loading");
      setOverlayMessage(statusMessage);
    } else if (successMessage) {
      setShowOverlay(true);
      setOverlayStatus("success");
      setOverlayMessage(successMessage);

      const timer = setTimeout(() => {
        setShowOverlay(false);
      }, SUCCESS_TIMEOUT_MS);

      return () => clearTimeout(timer);
    } else if (errorMessage) {
      setShowOverlay(true);
      setOverlayStatus("error");
      setOverlayMessage(errorMessage);

      const timer = setTimeout(() => {
        setShowOverlay(false);
      }, ERROR_TIMEOUT_MS);

      return () => clearTimeout(timer);
    } else {
      setShowOverlay(false);
    }
  }, [isLoading, successMessage, errorMessage, statusMessage]);

  const hasMandatoryFields =
    !mandatoryFields ||
    !mandatoryFields.length ||
    mandatoryFields?.every((field) => !!field);

  const isButtonDisabled = !enabled || !hasMandatoryFields;

  // Function to close the overlay manually
  const handleCloseOverlay = () => {
    if (overlayStatus !== "loading") {
      setShowOverlay(false);
      if (onClose) onClose();
    }
  };

  return (
    <div className="md:relative md:bg-transparent fixed bottom-0 left-0 right-0 p-4 bg-white border-t">
      {/* Overlay */}
      {showOverlay && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
            {/* Close button - only enabled when not loading */}
            {overlayStatus !== "loading" && (
              <button
                onClick={handleCloseOverlay}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}

            {/* Status icon */}
            <div className="flex justify-center mb-4">
              {overlayStatus === "loading" && (
                <svg
                  className="animate-spin h-10 w-10 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              {overlayStatus === "success" && (
                <svg
                  className="h-10 w-10 text-success"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
              {overlayStatus === "error" && (
                <svg
                  className="h-10 w-10 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>

            {/* Status message */}
            <p className="text-center text-gray-700 mb-4">{overlayMessage}</p>

            {/* Progress bar - only shown when loading and progressValue is provided */}
            {overlayStatus === "loading" && progressValue > 0 && (
              <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                <div
                  className="bg-primary h-4 rounded-full transition-all duration-300"
                  style={{ width: `${progressValue}%` }}
                ></div>
              </div>
            )}
          </div>
        </div>
      )}

      {!hasMandatoryFields && (
        <span className="text-red-500 text-xs md:text-sm relative md:absolute md:top-[-1em]">
          Debes completar todos los campos marcados con *
        </span>
      )}

      {!isLoading && !successMessage && (
        <>
          <div className="flex justify-center mt-2">
            <button
              type="submit"
              className={`max-w-sm w-full py-2 px-4 rounded ${
                isButtonDisabled
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-primary hover:bg-primary-light text-white cursor-pointer"
              }`}
              disabled={isButtonDisabled}
            >
              {children}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
