import generalTemplate from "@/components/HOC/templates/generalTemplate";
import ContactForm from "../components/Contact/ContactForm";
import { API_ROUTE_CONTACT_TEAMS } from "@/constants";

function ContactUsersPage() {
  return (
    <div className="bg-black flex items-center text-white mt-8 overflow-y-auto">
      <ContactForm
        toEmail={process.env.REACT_APP_TEAMS_CONTACT_EMAIL || ""}
        route={API_ROUTE_CONTACT_TEAMS}
      />
    </div>
  );
}

export default generalTemplate(ContactUsersPage);
