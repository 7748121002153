import ReactGA from 'react-ga4';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Initialize GA4
export const initGA = (measurementId: string) => {
  ReactGA.initialize(measurementId);
};

// Track page views
export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Track events
export const trackEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label
  });
};

// Track user authentication
export const trackUserAuth = (method: string, success: boolean) => {
  trackEvent('Authentication', method, success ? 'Success' : 'Failed');
};

// Track video interactions
export const trackVideoInteraction = (videoId: string) => {
  window.dataLayer.push({
    event: 'stream_view',
    videoId: videoId,
    videoAction: 'play'
  });
  
  trackEvent('Video', 'play', videoId);
};

// Track user interactions
export const trackUserInteraction = (action: string, label?: string) => {
  trackEvent('User Interaction', action, label);
}; 