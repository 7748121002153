import { API_ROUTE_VODS_LIST, API_ROUTE_VODS_LIST_PUBLIC } from "@constants";
import { useRequest } from "./useRequest";
import { IVod } from "./useVod";
import { Sport } from "@/constants/sports";
export interface IVodsListResponse {
  vodsList: IVod[] | null;
  isLoading: boolean;
  errorMessage: string;
}

export function useVodsList(
  sport: Sport,
  publicEndpoint?: boolean
): IVodsListResponse {
  const { data, isLoading, errorMessage } = useRequest<IVod[]>({
    url: `${
      publicEndpoint ? API_ROUTE_VODS_LIST_PUBLIC : API_ROUTE_VODS_LIST
    }?sport=${sport}`,
    method: "GET",
  });

  return { vodsList: data?.vods, isLoading, errorMessage };
}
