import { API_BASE_URL } from "./constants";

const config = {
  app: {
    head: {
      title: "Baseados.tv",
      charset: "utf-8",
      viewport: "width=device-width, initial-scale=1",
    },
    timeouts: {
      successOverlay: 3000,
      errorOverlay: 5000,
      navigationDelay: 2000,
    },
  },
  public: {
    apiUrl: API_BASE_URL,
    appName: "Baseados.tv",
  },
};

export default config;
