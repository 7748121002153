export const IMAGOTYPE_PRIMARY_COLOR =
  "/img/Imagotipo Horizontal - A Color.svg";
export const IMAGOTYPE_PRIMARY_NEGATIVE = "/img/Imagotipo Horizontal - N.svg";
export const IMAGOTYPE_PRIMARY_POSITIVE = "/img/Imagotipo Horizontal - P.svg";

export const IMAGOTYPE_SECONDARY_COLOR =
  "/img/Imagotipo Secundario - A Color.svg";
export const IMAGOTYPE_SECONDARY_NEGATIVE = "/img/Imagotipo Secundario - N.svg";
export const IMAGOTYPE_SECONDARY_POSITIVE = "/img/Imagotipo Secundario - P.svg";

export const ISOTYPE_COLOR = "/img/Isotipo - A Color.svg";
export const ISOTYPE_NEGATIVE = "/img/Isotipo - N.svg";
export const ISOTYPE_POSITIVE = "/img/Isotipo - P.svg";

export const LOGO_COLOR = "/img/Logotipo - A Color.svg";
export const LOGO_NEGATIVE = "/img/Logotipo - N.svg";
export const LOGO_POSITIVE = "/img/Logotipo - P.svg";

export const DEFAULT_VOD_THUMBNAIL = IMAGOTYPE_SECONDARY_COLOR;
export const DEFAULT_VOD_THUMBNAIL_FOOTBALL = "/img/landing/fondo_futbol_1.jpg";
export const DEFAULT_VOD_THUMBNAIL_BASKETBALL = "/img/landing/fondo_basket_1.jpg";

export const LOGIN_BACKGROUND = "/img/login.jpg";
export const NO_RESULTS = "/img/no-results.svg";
export const NO_RESULTS_SECONDARY = "/img/no-results-secondary.svg";
