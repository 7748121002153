import React, { useState } from "react";
import {
  LOGIN_FORMFEEDBACK_EMAIL,
  LOGIN_FORMFEEDBACK_PASSWORD,
} from "@constants/feedback";
import { useAuthStore } from "@hooks/useAuthStore";
import { useUserStore } from "@hooks/useUserStore";
import SpinnerLoader from "@components/SpinnerLoader";
import MessageError from "@components/MessageError";

const FormLogin: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const validateLoginForm = () => {
    if (!username.trim()) {
      authStore.setErrorMessage(LOGIN_FORMFEEDBACK_EMAIL);
      return false;
    } else if (!password.trim()) {
      authStore.setErrorMessage(LOGIN_FORMFEEDBACK_PASSWORD);
      return false;
    }
    authStore.setErrorMessage("");
    return true;
  };

  const logIn = () => {
    if (validateLoginForm()) {
      authStore.logIn(username, password, rememberPassword);
    }
  };

  if (authStore.isLoading) {
    return null;
  }

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="space-y-4 md:space-y-6"
      action="#"
    >
      {!authStore.isLoading && (
        <>
          <div>
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-medium"
            >
              Email o ID de usuario
            </label>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              name="username"
              id="username"
              autoComplete="username"
              className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 border-gray-600 placeholder-gray-400 focus:ring-primary-light focus:border-primary-light"
              placeholder="usuario@email.com"
              required
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium"
            >
              Contraseña
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              placeholder="•••••••"
              autoComplete="current-password"
              className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 border-gray-600 placeholder-gray-400 focus:ring-primary-light focus:border-primary-light"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="w-4 h-4 border rounded focus:ring-3 bg-gray-700 border-gray-600 focus:ring-primary-600 ring-offset-gray-800"
                  onClick={() => setRememberPassword(!rememberPassword)}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="remember" className="text-gray-700">
                  Recordar contraseña
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      {(authStore.isLoading || userStore.isLoading) && (
        <SpinnerLoader className="text-white text-center mt-4" />
      )}
      {authStore.errorMessage && (
        <MessageError message={authStore.errorMessage} />
      )}

      {!authStore.isLoading && !userStore.isLoading && (
        <button
          type="submit"
          className="w-full bg-primary hover:bg-primary-light text-white font-bold py-2 px-4 rounded"
          onClick={logIn}
        >
          Iniciar sesión
        </button>
      )}
    </form>
  );
};

export default FormLogin;
