import React, { createContext, useContext, useState, useCallback } from "react";
import { API_ROUTE_LIVE_USER_ME, API_ROUTE_USERS } from "@constants";
import { HTTP_GENERAL_ERROR } from "@constants/network";
import { SUCCESS_MESSAGE_DELAY } from "@constants/feedback";
import { Sport } from "@constants/sports";
import config from "@/app.config";
import { useAuthStore } from "./useAuthStore";

interface TLiveUserContent {
  id?: string;
  email?: string;
  dateCreated?: string;
  isLive?: boolean;
  slug?: string;
  summary?: string;
  thumbnail?: string;
  title?: string;
  streamAuthKey?: string;
  sport?: Sport;
  sportEventId?: string;
}

interface LiveUserContextType {
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  myUser: TLiveUserContent;
  requestedUser: TLiveUserContent;
  signUp: (email: string, password: string) => Promise<void>;
  getMyData: () => Promise<void>;
  setErrorMessage: (message: string) => void;
  updateUser: (formData: FormData) => Promise<void>;
}

const LiveUserContext = createContext<LiveUserContextType | undefined>(
  undefined
);

export const LiveUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [myUser, setMyUser] = useState<TLiveUserContent>({});
  const [requestedUser, setRequestedUser] = useState<TLiveUserContent>({});

  const authStore = useAuthStore();

  const clearMessages = useCallback(() => {
    setTimeout(() => {
      setSuccessMessage("");
    }, SUCCESS_MESSAGE_DELAY);
  }, []);

  const signUp = useCallback(async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        `${config.public.apiUrl}${API_ROUTE_USERS}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getMyData = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        `${config.public.apiUrl}${API_ROUTE_LIVE_USER_ME}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authStore.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = await response.json();
      if (response.ok) {
        setMyUser(data.liveUser);
      } else {
        setErrorMessage(data.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [authStore.token]);

  const updateUser = useCallback(
    async (formData: FormData) => {
      try {
        setIsLoading(true);
        setErrorMessage("");
        const response = await fetch(
          `${config.public.apiUrl}${API_ROUTE_LIVE_USER_ME}`,
          {
            method: "PATCH",
            body: formData,
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update user");
        }
        setSuccessMessage("Usuario actualizado correctamente");
        clearMessages();
      } catch (error) {
        setErrorMessage("Error al actualizar el usuario");
      } finally {
        setIsLoading(false);
      }
    },
    [authStore.token, clearMessages]
  );

  const value = {
    isLoading,
    successMessage,
    errorMessage,
    myUser,
    requestedUser,
    signUp,
    getMyData,
    setErrorMessage,
    updateUser,
  };

  return (
    <LiveUserContext.Provider value={value}>
      {children}
    </LiveUserContext.Provider>
  );
};

export const useLiveUserStore = () => {
  const context = useContext(LiveUserContext);
  if (context === undefined) {
    throw new Error("useLiveUserStore must be used within a LiveUserProvider");
  }
  return context;
};
